import _ from "lodash";
import moment from "moment";
import { CompanyConstant, compFspRequiredFieldList } from "presentation/constant/Company/CompanyConstant";
import { FSPCountingDroOpts } from "presentation/constant/DropDownOptions/Company/FSPCountingDroOpts";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCompanyFspVM } from "presentation/hook/Company/useCompanyFspVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";

import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, HPHInputTextarea, IFieldValue, Loader, TimePicker } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyFspFormPanel = () => {   
    const [ companyDtlState ] = useCompanyDetailTracked();
    const companyFspVM = useCompanyFspVM();
    const companyDtlVM = useCompanyDetailVM();
    const messageBarVM = useMessageBarVM(); 

    const COMPANY_DTL_CONST = CompanyConstant.Detail;    
    const [isLoading, setIsLoading] = useState(false);
    const {dynamicOptions} = companyDtlState
    const {isAdd,isEditable, isEditCompDtlInfo, allFormState, isSaveClicked} = companyDtlState.viewState
    const {currentCompanyFsp} = companyDtlState.companyFspState

    useEffect(() => {
        if (companyDtlState.viewState.isShowFsp) { 
            setIsLoading(true);           
            companyFspVM.loadDropdownOption().then(data => {
                companyFspVM.onInitDefaultValue();
            });
            setIsLoading(false);
        }   
    }, [companyFspVM, companyDtlState.isShowRightPanel, companyDtlState.viewState.isShowFsp]);

    const handleCancelClick = useCallback(() => {
        companyDtlVM.onCancel();
    }, [companyDtlVM])

    const memoSubChgTypeOptions = useMemo(() => {
        const groupOfSubChgTypeOptions = companyDtlState.dynamicOptions.subChargeTypeDropdownOptions[currentCompanyFsp.chargeType??""];
        const commonSubChgTypeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfSubChgTypeOptions??[])), 'value'), 'dropdownLabel');
        return commonSubChgTypeOptions;
    }, [companyDtlState.dynamicOptions.subChargeTypeDropdownOptions, currentCompanyFsp.chargeType]);
    
    const handleSaveClick = useCallback(() => {
        if(currentCompanyFsp){
            setIsLoading(true);
            
            if(isAdd){
                currentCompanyFsp.companyId = companyDtlState.companyId;                
            }
            companyDtlVM.onSaveClicked();
            companyFspVM.onSave(currentCompanyFsp, isAdd).then((data) => {
                if (data && data["saveCompanyFspFailed"]) {
                    setIsLoading(false);
                    messageBarVM.showError(data["saveCompanyFspFailed"]?.toString());
                    return;
                }
                
                companyDtlState.companyId && companyFspVM.initialFspTableData(companyDtlState.companyId).then(()=>{
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                });
            }).catch(()=>{
                setIsLoading(false);
                messageBarVM.showError('Save Data Failed.');
            });
        }
    },[companyDtlState.companyId, companyDtlVM, companyFspVM, currentCompanyFsp, isAdd, messageBarVM])

    const isDisabled = useCallback(() => {
        if(!isEditCompDtlInfo || currentCompanyFsp?.status === "FINALIZED" || currentCompanyFsp?.status === "PROPOSED") return true;

        return false;
    }, [currentCompanyFsp?.status, isEditCompDtlInfo])

    const getStartTime = (hours:number, min:number) => {
        const sysdate = moment();
        sysdate.hours(hours);
        sysdate.minutes(min);
        return sysdate
    }

    const onNumberFieldChange = useCallback((e:any, fieldName:string, fieldValue:any) => {
        companyFspVM.onFieldChange(fieldName, fieldValue);
    }, [companyFspVM]);

    const handleTextAreaChange = useCallback((event:any, fieldName:string)  => {
        const fieldValue = event.target.value;
        companyFspVM.onFieldChange(fieldName, fieldValue);
    },[companyFspVM])

    const selectedFspCountingFromVal = useMemo(() => {
        return FSPCountingDroOpts().getFromValueByKey(currentCompanyFsp.chargeType??"", currentCompanyFsp.fspCountingFrom??"");     
    },[currentCompanyFsp.chargeType, currentCompanyFsp.fspCountingFrom])

    const selectedFspCountingToVal = useMemo(() => {
        return FSPCountingDroOpts().getToValueByKey(currentCompanyFsp.chargeType??"", currentCompanyFsp.fspCountingTo??"");        
    },[currentCompanyFsp.chargeType, currentCompanyFsp.fspCountingTo])

    const onSubChargeTypeChange = useCallback((fieldKey: string, fieldValue: any)  => {
        companyFspVM.onSubChargeTypeChange(fieldKey, currentCompanyFsp.chargeType??"", fieldValue);
    },[companyFspVM, currentCompanyFsp.chargeType])

    const onCntrMovementChange = useCallback((fieldKey: string, fieldValue: any)  => {
        companyFspVM.onCntrMovementChange(fieldKey, currentCompanyFsp.chargeType??"", fieldValue);
    },[companyFspVM, currentCompanyFsp.chargeType])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isDisabled()}
                    label={COMPANY_DTL_CONST.EFFECTIVE_DATE}
                    width="200px"
                    date={currentCompanyFsp?.effectiveDate??null}
                    fieldName="effectiveDate"
                    errorMessage={allFormState?allFormState["effectiveDate"]:""}
                    onDateChange={companyFspVM.onFieldChange}/>
        </div>
    , [COMPANY_DTL_CONST.EFFECTIVE_DATE, allFormState, companyFspVM.onFieldChange, currentCompanyFsp?.effectiveDate, isDisabled])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"205px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.operatingTml??''}
                fieldValue={currentCompanyFsp?.operatingTml??''}
                fieldLabel={COMPANY_DTL_CONST.OPERATING_TML}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                options={dynamicOptions.operatingTmlDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.OPERATING_TML, allFormState, companyFspVM, currentCompanyFsp?.operatingTml, dynamicOptions.operatingTmlDropdownOptions, isDisabled])

    const memoConsortiumCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.consortiumCode??''}
                fieldValue={currentCompanyFsp?.consortiumCode??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.CONSORTIUM_CODE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'consortiumCode'}
                options={dynamicOptions.consortiumCodeDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.CONSORTIUM_CODE, allFormState, companyFspVM, currentCompanyFsp?.consortiumCode, dynamicOptions.consortiumCodeDropdownOptions, isDisabled])

    const memoServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.serviceCode??''}
                fieldValue={currentCompanyFsp?.serviceCode??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.SERVICE_CODE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceCode'}
                options={dynamicOptions.serviceCodeDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.SERVICE_CODE, allFormState, companyFspVM, currentCompanyFsp?.serviceCode, dynamicOptions.serviceCodeDropdownOptions, isDisabled])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"205px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                //isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp.chargeType??""}
                fieldValue={currentCompanyFsp.chargeType??""}
                fieldLabel={COMPANY_DTL_CONST.CHARGE_TYPE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                options={dynamicOptions.fspChargeTypeDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compFspRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onChargeTypeChange(fieldKey, fieldValue && fieldValue.toString())} />
        </div>
    , [allFormState, isDisabled, currentCompanyFsp.chargeType, COMPANY_DTL_CONST.CHARGE_TYPE, dynamicOptions.fspChargeTypeDropdownOptions, isSaveClicked, companyFspVM])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.subChargeType??''}
                fieldValue={currentCompanyFsp?.subChargeType??''}
                fieldLabel={COMPANY_DTL_CONST.SUB_CHARGE_TYPE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                options={memoSubChgTypeOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    onSubChargeTypeChange(fieldKey, fieldValue && fieldValue.toString())} />
        </div>
    , [COMPANY_DTL_CONST.SUB_CHARGE_TYPE, allFormState, currentCompanyFsp?.subChargeType, isDisabled, memoSubChgTypeOptions, onSubChargeTypeChange])

    const memoShipmentType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                //isShowOptional={true}                
                isMultipleDropDown={true}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.shipmentType??''}
                fieldValue={currentCompanyFsp?.shipmentType?.split(",").filter(item => item.trim() !== '')??''}
                allValues={currentCompanyFsp?.shipmentType??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.SHIPMENT_TYPE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'shipmentType'}
                options={dynamicOptions.shipmentTypeDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compFspRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue as string[] , fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.SHIPMENT_TYPE, allFormState, companyFspVM, currentCompanyFsp?.shipmentType, dynamicOptions.shipmentTypeDropdownOptions, isDisabled, isSaveClicked])

    const memoCntrMovement = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"205px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.cntrMovement??''}
                fieldValue={currentCompanyFsp?.cntrMovement??''}
                fieldLabel={COMPANY_DTL_CONST.CNTR_MOVEMENT}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrMovement'}
                options={dynamicOptions.cntrMovementDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    onCntrMovementChange(fieldKey, fieldValue && fieldValue.toString())} />
        </div>
    , [COMPANY_DTL_CONST.CNTR_MOVEMENT, allFormState, currentCompanyFsp?.cntrMovement, dynamicOptions.cntrMovementDropdownOptions, isDisabled, onCntrMovementChange])

    const memoCntrStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.cntrStatus??''}
                fieldValue={currentCompanyFsp?.cntrStatus??''}
                fieldLabel={COMPANY_DTL_CONST.CNTR_STATUS}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                options={dynamicOptions.cntrStatusDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.CNTR_STATUS, allFormState, companyFspVM, currentCompanyFsp?.cntrStatus, dynamicOptions.cntrStatusDropdownOptions, isDisabled])

    const memoOpsLine = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={true}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.owner??''}
                fieldValue={currentCompanyFsp?.owner?.split(",").filter(item => item.trim() !== '')??''}
                allValues={currentCompanyFsp?.owner??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.OPS_LINE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'owner'}
                options={dynamicOptions.opsLineDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.OPS_LINE, allFormState, companyFspVM, currentCompanyFsp?.owner, dynamicOptions.opsLineDropdownOptions, isDisabled])

    const memoForwarderCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"205px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={true}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.forwarderCode??''}
                fieldValue={currentCompanyFsp?.forwarderCode?.split(",").filter(item => item.trim() !== '')??''}
                allValues={currentCompanyFsp?.forwarderCode??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.FORWARDER_CODE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'forwarderCode'}
                options={dynamicOptions.forwardCodeDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.FORWARDER_CODE, allFormState, companyFspVM, currentCompanyFsp?.forwarderCode, dynamicOptions.forwardCodeDropdownOptions, isDisabled])

    const memoForwarderRef = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}                
                readOnlyValue={currentCompanyFsp?.forwarderReference??''}
                fieldValue={currentCompanyFsp?.forwarderReference??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.FORWARDER_REF}                
                fieldType={FieldType.TEXT}
                fieldKey={'forwarderReference'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.FORWARDER_REF, allFormState, companyFspVM, currentCompanyFsp?.forwarderReference, isDisabled])

    const memoFirstTierFsp = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.FSP.FIRST_TIER_FSP}
                maxLength={4}
                width="200px"
                fieldName={"firstTierFSP"}
                value={currentCompanyFsp?.firstTierFSP??''}
                errorMessage={allFormState?.firstTierFSP}
                disabled={isDisabled()}             
                onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
    , [COMPANY_DTL_CONST.FSP.FIRST_TIER_FSP, allFormState?.firstTierFSP, currentCompanyFsp?.firstTierFSP, isDisabled, onNumberFieldChange])

    const memoSecondTierFsp = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"205px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.FSP.SECOND_TIER_FSP}
                maxLength={4}
                width="205px"
                fieldName={"secondTierFSP"}
                value={currentCompanyFsp?.secondTierFSP??''}
                errorMessage={allFormState?.secondTierFSP}
                disabled={isDisabled()}                
                onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
    , [COMPANY_DTL_CONST.FSP.SECOND_TIER_FSP, allFormState?.secondTierFSP, currentCompanyFsp?.secondTierFSP, isDisabled, onNumberFieldChange])

    const memoUom = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={false}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.fspUOM??''}
                fieldValue={currentCompanyFsp?.fspUOM??''}
                fieldLabel={COMPANY_DTL_CONST.UOM}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fspUOM'}
                options={dynamicOptions.fspUomDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compFspRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.UOM, allFormState, companyFspVM, currentCompanyFsp?.fspUOM, dynamicOptions.fspUomDropdownOptions, isDisabled, isSaveClicked])

    const memoCargoCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.cargoCode??''}
                fieldValue={currentCompanyFsp?.cargoCode??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.CARGO_CODE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cargoCode'}
                options={dynamicOptions.cargoCodeDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.CARGO_CODE, allFormState, companyFspVM, currentCompanyFsp?.cargoCode, dynamicOptions.cargoCodeDropdownOptions, isDisabled])

    const memoVesselCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"205px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}                
                readOnlyValue={currentCompanyFsp?.vesselCode??''}
                fieldValue={currentCompanyFsp?.vesselCode??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.VESSEL_CODE}                
                fieldType={FieldType.TEXT}
                fieldKey={'vesselCode'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.VESSEL_CODE, allFormState, companyFspVM, currentCompanyFsp?.vesselCode, isDisabled])

    const memoVoyageCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}                
                readOnlyValue={currentCompanyFsp?.voyageCode??''}
                fieldValue={currentCompanyFsp?.voyageCode??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.VOYAGE_CODE}                
                fieldType={FieldType.TEXT}
                fieldKey={'voyageCode'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.VOYAGE_CODE, allFormState, companyFspVM, currentCompanyFsp?.voyageCode, isDisabled])

    const memoGraceHour = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <TimePicker
                    width='200px'
                    maxLength={2}
                    disabled={isDisabled()}
                    label={COMPANY_DTL_CONST.FSP.GRACE_HOUR_MIN}
                    type="number"                    
                    minLength={2}
                    value={getStartTime(currentCompanyFsp.graceHour??0, currentCompanyFsp.graceMinute??0).toDate()}
                    onChange={(e: any) => companyFspVM.onTimePickerChange(e, 'graceHour', 'graceMinute')}/>
        </div>
    , [COMPANY_DTL_CONST.FSP.GRACE_HOUR_MIN, companyFspVM, currentCompanyFsp.graceHour, currentCompanyFsp.graceMinute, isDisabled])

    const memoCalendarAdj = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"205px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={true}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.applyCalendarAdj??""}
                fieldValue={currentCompanyFsp?.applyCalendarAdj?.split(",").filter(item => item.trim() !== '')??""}
                fieldLabel={COMPANY_DTL_CONST.FSP.CALENDAR_ADJ}
                allValues={currentCompanyFsp?.applyCalendarAdj??""}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'applyCalendarAdj'}
                options={dynamicOptions.calendarAdjDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.CALENDAR_ADJ, allFormState, companyFspVM, currentCompanyFsp?.applyCalendarAdj, dynamicOptions.calendarAdjDropdownOptions, isDisabled])

    const memoFspCountingFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"205px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                //isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={/*currentCompanyFsp?.fspCountingFrom??''*/selectedFspCountingFromVal}
                fieldValue={/*currentCompanyFsp?.fspCountingFrom??''*/selectedFspCountingFromVal}
                fieldLabel={COMPANY_DTL_CONST.FSP.FSP_COUNTING_FROM}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fspCountingFrom'}
                options={dynamicOptions.fspCountingFromDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compFspRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFspCountingChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.FSP_COUNTING_FROM, allFormState, companyFspVM, dynamicOptions.fspCountingFromDropdownOptions, isDisabled, isSaveClicked, selectedFspCountingFromVal])

    const memoFspCountingTo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                //isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={/*currentCompanyFsp?.fspCountingTo??''*/selectedFspCountingToVal}
                fieldValue={/*currentCompanyFsp?.fspCountingTo??''*/selectedFspCountingToVal}
                fieldLabel={COMPANY_DTL_CONST.FSP.FSP_COUNTING_TO}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fspCountingTo'}
                options={dynamicOptions.fspCountingToDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compFspRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFspCountingChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.FSP_COUNTING_TO, allFormState, companyFspVM, dynamicOptions.fspCountingToDropdownOptions, isDisabled, isSaveClicked, selectedFspCountingToVal])

    const memoDirection = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={false}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyFsp?.fspCountingDirection??''}
                fieldValue={currentCompanyFsp?.fspCountingDirection??''}
                fieldLabel={COMPANY_DTL_CONST.FSP.DIRECTION}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fspCountingDirection'}
                options={dynamicOptions.directionDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compFspRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyFspVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.FSP.DIRECTION, allFormState, companyFspVM, currentCompanyFsp?.fspCountingDirection, dynamicOptions.directionDropdownOptions, isDisabled, isSaveClicked])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"670px", marginBottom:"24px"}}>
            <HPHInputTextarea
                label={COMPANY_DTL_CONST.REMARKS}
                width="670px"
                rows={3}
                disabled={isDisabled()}
                resizable={false}
                helpIcon={false}
                optional={true}           
                value={currentCompanyFsp?.remarks??''}
                onChange={(e) => handleTextAreaChange(e, "remarks")} />
        </div>
    , [COMPANY_DTL_CONST.REMARKS, currentCompanyFsp?.remarks, handleTextAreaChange, isDisabled])    

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"670px", marginBottom:"24px"}}>
            <HPHInputTextarea
                label={COMPANY_DTL_CONST.REJECT_REASON}
                width="670px"
                rows={3}
                disabled={true}
                resizable={false}
                helpIcon={false}                
                value={currentCompanyFsp?.rejectReason??''}
                onChange={() =>{}} />           
        </div>
    , [COMPANY_DTL_CONST.REJECT_REASON, currentCompanyFsp?.rejectReason])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={COMPANY_DTL_CONST.ACTIVE_IND} 
                fieldType={FieldType.TEXT} 
                isReadOnly={true}
                onFieldChange={()=>{}}/>
            <HPHCheckbox
                errorMessages={allFormState}
                label={''}
                checked={currentCompanyFsp?.activeInd==='Y'?true:false}
                disabled={!isEditCompDtlInfo}
                onChange={(e) => companyFspVM.onCheckboxChange(e.checked, "activeInd")}
        />
        </div>
    , [COMPANY_DTL_CONST.ACTIVE_IND, allFormState, companyFspVM, currentCompanyFsp?.activeInd, isEditCompDtlInfo])

    return (
        <div className='side-form-content-wrapper' style={{paddingBottom: "5px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{COMPANY_DTL_CONST.FSP.COMPANY_FSP}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            
            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoEffectiveDate}
                            {memoOperatingTml}
                            {memoConsortiumCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoServiceCode}
                            {memoChargeType}
                            {memoSubChargeType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoShipmentType}
                            {memoCntrMovement}
                            {memoCntrStatus}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoOpsLine}
                            {memoForwarderCode}
                            {memoForwarderRef}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoFirstTierFsp}
                            {memoSecondTierFsp}
                            {memoUom}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCargoCode}
                            {memoVesselCode}
                            {memoVoyageCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoFspCountingFrom}
                            {memoFspCountingTo}
                            {memoDirection}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoGraceHour}
                            {memoCalendarAdj}                        
                            {memoActiveInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRemarks}                            
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRejectReason}                            
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={()=>handleCancelClick()} />
                        <HPHButton label={isAdd?'Add':isEditable?'Update':'Save'} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />
                    </>
                }
            </div>           
            
        </div>
    )
}
export default memo(CompanyFspFormPanel);
