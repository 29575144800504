import { CountryEntity } from "../MasterData/CountryEntity"

export interface CompanyEntity {
    id: number,
    versionIdentifier: {
        version?: string
    }
    companyCode: string,
    masterCompanyId: number | null,
    masterCompany: CompanyEntity | null,
    allianceCompanyId: number | null,
    alliance: CompanyEntity | null,
    companyName: string,
    companyType: string,
    address1: string,
    address2: string | null,
    address3: string | null,
    applyFreightEmptyToOb?: string | null,
    status: "NEW" | "CONFIRMED",
    activeInd: "Y" | "N" | null,
    signOnDate: Date | null,
    countryCodeId: number | null,
    country:  CountryEntity | null,
    emChargeType: string | null,
    letPassAccountInd: "N" | "Y",
    emailAddress: string | null,
    mobileNo: string | null,
    agentNo: string | null,
    agentName: string | null,
    contactPerson: string | null,
    gstNo: string | null,
    ntnNo: string | null,
    customerType: string | null,


    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_ENTITY : CompanyEntity = {
    id: 0,
    versionIdentifier: {},
    companyCode: "",
    masterCompanyId: null,
    masterCompany: null,
    allianceCompanyId: null,
    alliance: null,
    companyName: "",
    companyType: "",
    address1: "",
    address2: null,
    address3: null,
    applyFreightEmptyToOb: null,
    status: "NEW",
    activeInd: "Y",
    signOnDate: null,
    countryCodeId: null,
    country: null,
    emChargeType: null,
    letPassAccountInd: "N",
    emailAddress: null,
    mobileNo: null,
    agentNo: null,
    agentName: null,
    contactPerson: null,
    gstNo: null,
    ntnNo: null,
    customerType: null,
}