import { PoolTypePolicy } from "constants/company/PoolTypePolicy";

export const PoolTypeDroOpts = () => {

    const getPoolTypeItemList = () => {
        let items = [];

        items.push(PoolTypePolicy.FREE_POOL1);
        items.push(PoolTypePolicy.FREE_POOL2);
        items.push(PoolTypePolicy.SUPP_POOL1);
        items.push(PoolTypePolicy.SUPP_POOL2);

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
            })) ?? [])];
    }

    return {
        getPoolTypeItemList        
    }
}