import { ChargeTypePolicy } from "constants/charge/ChargeTypePolicy";
import { FSPCountingKeyPolicy } from "constants/company/FSPCountingKeyPolicy"
import { FSPCountingValuePolicy } from "constants/company/FSPCountingValuePolicy"
import _ from "lodash";


export const FSPCountingDroOpts = () => {
    const ETB = {[FSPCountingKeyPolicy.ETB]: FSPCountingValuePolicy.ETB};
    const ETB_ADD_1 = {[FSPCountingKeyPolicy.ETB_ADD_1]: FSPCountingValuePolicy.ETB_ADD_1};
    const ETB_MINUS_1 = {[FSPCountingKeyPolicy.ETB_MINUS_1]: FSPCountingValuePolicy.ETB_MINUS_1};
    const ETD = {[FSPCountingKeyPolicy.ETD]: FSPCountingValuePolicy.ETD};
    const ETD_ADD_1 = {[FSPCountingKeyPolicy.ETD_ADD_1]: FSPCountingValuePolicy.ETD_ADD_1};
    const ETD_MINUS_1 = {[FSPCountingKeyPolicy.ETD_MINUS_1]: FSPCountingValuePolicy.ETD_MINUS_1};
    const DISCHARGING = {[FSPCountingKeyPolicy.DISCHARGE]: FSPCountingValuePolicy.DISCHARGING};
    const FIRST_DISCHARGING = {[FSPCountingKeyPolicy.FIRST_DISCHARGE]: FSPCountingValuePolicy.FIRST_DISCHARGING};
    const LAST_DISCHARGING = {[FSPCountingKeyPolicy.LAST_DISCHARGE]: FSPCountingValuePolicy.LAST_DISCHARGING};
    const DELIVERY_DATE = {[FSPCountingKeyPolicy.DEL_DATE]: FSPCountingValuePolicy.DELIVERY_DATE};
    const COS = {[FSPCountingKeyPolicy.COS_DATE]: FSPCountingValuePolicy.COS};
    const TO_COS = {[FSPCountingKeyPolicy.TO_COS_DATE]: FSPCountingValuePolicy.TO_COS};
    const LOADING = {[FSPCountingKeyPolicy.LOADING]: FSPCountingValuePolicy.LOADING};
    const FIRST_LOADING = {[FSPCountingKeyPolicy.FIRST_LOADING]: FSPCountingValuePolicy.FIRST_LOADING};
    const LAST_LOADING = {[FSPCountingKeyPolicy.LAST_LOADING]: FSPCountingValuePolicy.LAST_LOADING};
    const RECEIVE_DATE = {[FSPCountingKeyPolicy.RECEIVE_DATE]: FSPCountingValuePolicy.RECEIVE_DATE};
    const FIRST_LEG_ETB = {[FSPCountingKeyPolicy.FIRST_ETB]: FSPCountingValuePolicy.FIRST_LEG_ETB};
    const FIRST_LEG_ETB_ADD_1 = {[FSPCountingKeyPolicy.FIRST_ETB_ADD_1]: FSPCountingValuePolicy.FIRST_LEG_ETB_ADD_1};
    const FIRST_LEG_ETB_MINUS_1 = {[FSPCountingKeyPolicy.FIRST_ETB_MINUS_1]: FSPCountingValuePolicy.FIRST_LEG_ETB_MINUS_1};
    const FIRST_LEG_ETD = {[FSPCountingKeyPolicy.FIRST_ETD]: FSPCountingValuePolicy.FIRST_LEG_ETD};
    const FIRST_LEG_ETD_ADD_1 = {[FSPCountingKeyPolicy.FIRST_ETD_ADD_1]: FSPCountingValuePolicy.FIRST_LEG_ETD_ADD_1};
    const FIRST_LEG_ETD_MINUS_1 = {[FSPCountingKeyPolicy.FIRST_ETD_MINUS_1]: FSPCountingValuePolicy.FIRST_LEG_ETD_MINUS_1};
    const SECOND_LEG_ETB = {[FSPCountingKeyPolicy.SECOND_ETB]: FSPCountingValuePolicy.SECOND_LEG_ETB};
    const SECOND_LEG_ETB_ADD_1 = {[FSPCountingKeyPolicy.SECOND_ETB_ADD_1]: FSPCountingValuePolicy.SECOND_LEG_ETB_ADD_1};
    const SECOND_LEG_ETB_MINUS_1 = {[FSPCountingKeyPolicy.SECOND_ETB_MINUS_1]: FSPCountingValuePolicy.SECOND_LEG_ETB_MINUS_1};
    const SECOND_LEG_ETD = {[FSPCountingKeyPolicy.SECOND_ETD]: FSPCountingValuePolicy.SECOND_LEG_ETD};
    const SECOND_LEG_ETD_ADD_1 = {[FSPCountingKeyPolicy.SECOND_ETD_ADD_1]: FSPCountingValuePolicy.SECOND_LEG_ETD_ADD_1};
    const SECOND_LEG_ETD_MINUS_1 = {[FSPCountingKeyPolicy.SECOND_ETD_MINUS_1]: FSPCountingValuePolicy.SECOND_LEG_ETD_MINUS_1};
    const FIRST_LEG_DISCHARGING = {[FSPCountingKeyPolicy.DISCHARGE]: FSPCountingValuePolicy.FIRST_LEG_DISCHARGING};
    const FIRST_LEG_FIRST_DISCHARGING = {[FSPCountingKeyPolicy.FIRST_DISCHARGE]: FSPCountingValuePolicy.FIRST_LEG_FIRST_DISCHARGING};
    const FIRST_LEG_LAST_DISCHARGING = {[FSPCountingKeyPolicy.LAST_DISCHARGE]: FSPCountingValuePolicy.FIRST_LEG_LAST_DISCHARGING};
    const SECOND_LEG_LOADING = {[FSPCountingKeyPolicy.LOADING]: FSPCountingValuePolicy.SECOND_LEG_LOADING};
    const SECOND_LEG_FIRST_LOADING = {[FSPCountingKeyPolicy.FIRST_LOADING]: FSPCountingValuePolicy.SECOND_LEG_FIRST_LOADING};
    const SECOND_LEG_LAST_LOADING = {[FSPCountingKeyPolicy.LAST_LOADING]: FSPCountingValuePolicy.SECOND_LEG_LAST_LOADING};
    const GATE_IN = {[FSPCountingKeyPolicy.GATE_IN]: FSPCountingValuePolicy.GATE_IN};
    const GATE_OUT = {[FSPCountingKeyPolicy.GATE_OUT]: FSPCountingValuePolicy.GATE_OUT};
    const STORAGE_FROM_DATE = {[FSPCountingKeyPolicy.STORAGE_FROM_DATE]: FSPCountingValuePolicy.STORAGE_FROM_DATE};
    const STORAGE_TO_DATE = {[FSPCountingKeyPolicy.STORAGE_TO_DATE]: FSPCountingValuePolicy.STORAGE_TO_DATE};
    const GATE_CLOSING_TIME = {[FSPCountingKeyPolicy.GATE_CLOSING_TIME]: FSPCountingValuePolicy.GATE_CLOSING_TIME};
    const POWER_PLUG_ON = {[FSPCountingKeyPolicy.POWER_PLUG_ON]: FSPCountingValuePolicy.POWER_PLUG_ON};
    const POWER_PLUG_OFF = {[FSPCountingKeyPolicy.POWER_PLUG_OFF]: FSPCountingValuePolicy.POWER_PLUG_OFF};
    
    const getFSPCountingFromByChargeType = (chargeType: string) => {
        let items:{ [key: string]: string } = {};

        if(chargeType === ChargeTypePolicy.CHARGE_TYPE_ITM){
            items = {
                ...ETB, ...ETB_ADD_1, ...ETB_MINUS_1, 
                ...ETD, ...ETD_ADD_1, ...ETD_MINUS_1, 
                ...DISCHARGING, ...FIRST_DISCHARGING, ...LAST_DISCHARGING
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_OBS){
            items = {
                ...RECEIVE_DATE
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_TSS){
            items = {
                ...FIRST_LEG_ETB, ...FIRST_LEG_ETB_ADD_1, ...FIRST_LEG_ETB_MINUS_1, 
                ...FIRST_LEG_ETD, ...FIRST_LEG_ETD_ADD_1, ...FIRST_LEG_ETD_MINUS_1,
                ...FIRST_LEG_DISCHARGING, ...FIRST_LEG_FIRST_DISCHARGING, ...FIRST_LEG_LAST_DISCHARGING
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_GS){
            items = {
                ...DISCHARGING, ...FIRST_DISCHARGING, ...LAST_DISCHARGING,
                ...GATE_IN, ...COS, ...RECEIVE_DATE
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_RFH){
            items = {
                ...DISCHARGING, ...FIRST_DISCHARGING, ...LAST_DISCHARGING,
                ...RECEIVE_DATE, ...POWER_PLUG_ON
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_DEL){
            items = {
                ...STORAGE_FROM_DATE
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_RAIL){
            items = {
                ...STORAGE_FROM_DATE
            };
        }

        return [
            ...(_.map(
                _.toPairs(items), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: key, value: value }
                )
            ) ?? [])
        ];
    }

    const getFSPCountingToByChargeType = (chargeType: string) => {
        let items:{ [key: string]: string } = {};

        if(chargeType === ChargeTypePolicy.CHARGE_TYPE_ITM){
            items = {
                ...DELIVERY_DATE
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_OBS){
            items = {
                ...ETB, ...ETB_ADD_1, ...ETB_MINUS_1,
                ...ETD, ...ETD_ADD_1, ...ETD_MINUS_1,
                ...LOADING, ...FIRST_LOADING, ...LAST_LOADING,
                ...GATE_CLOSING_TIME
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_TSS){
            items = {
                ...SECOND_LEG_ETB, ...SECOND_LEG_ETB_ADD_1, ...SECOND_LEG_ETB_MINUS_1, 
                ...SECOND_LEG_ETD, ...SECOND_LEG_ETD_ADD_1, ...SECOND_LEG_ETD_MINUS_1,
                ...SECOND_LEG_LOADING, ...SECOND_LEG_FIRST_LOADING, ...SECOND_LEG_LAST_LOADING
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_GS){
            items = {
                ...LOADING, ...FIRST_LOADING, ...LAST_LOADING,
                ...GATE_OUT, ...TO_COS
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_RFH){
            items = {
                ...LOADING, ...FIRST_LOADING, ...LAST_LOADING,
                ...GATE_OUT, ...POWER_PLUG_OFF
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_DEL){
            items = {
                ...STORAGE_TO_DATE
            };
        } else if(chargeType === ChargeTypePolicy.CHARGE_TYPE_RAIL){
            items = {
                ...STORAGE_TO_DATE
            };
        }

        return [
            ...(_.map(
                _.toPairs(items), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: key, value: value }
                )
            ) ?? [])
        ];
    }

    const getFSPCountingFromBySubChargeType = (chargeType: string, subChargeType: string) => {
        let items:{ [key: string]: string } = {};

        if(subChargeType === "COS"){
            items = {
                ...DISCHARGING, ...GATE_IN, ...COS, ...RECEIVE_DATE
            };
        } else if(subChargeType === "CSR"){
            items = {
                ...GATE_IN, ...RECEIVE_DATE
            };
        } else if(subChargeType === "EM"){
            items = {
                ...DISCHARGING, ...LAST_DISCHARGING, ...RECEIVE_DATE
            };
        } else if(subChargeType === "EC"){
            items = {
                ...DISCHARGING, ...LAST_DISCHARGING, ...RECEIVE_DATE
            };
        } else if(subChargeType === "EI"){
            items = {
                ...GATE_IN, ...RECEIVE_DATE
            };
        } else if(subChargeType === "ITM"){
            items = {
                ...ETB, ...ETB_ADD_1, ...ETB_MINUS_1,
                ...ETD, ...ETD_ADD_1, ...ETD_MINUS_1,
                ...DISCHARGING, ...FIRST_DISCHARGING, ...LAST_DISCHARGING
            };
        } else if(subChargeType === "INTERIM" && chargeType === ChargeTypePolicy.CHARGE_TYPE_ITM){
            items = {
                ...ETB, ...ETB_ADD_1, ...ETB_MINUS_1,
                ...ETD, ...ETD_ADD_1, ...ETD_MINUS_1,
                ...DISCHARGING, ...FIRST_DISCHARGING, ...LAST_DISCHARGING
            };
        } else if(subChargeType === "INTERIM" && chargeType === ChargeTypePolicy.CHARGE_TYPE_GS){
            items = {
                ...DISCHARGING, ...FIRST_DISCHARGING, ...LAST_DISCHARGING,
                ...GATE_IN, ...COS, ...RECEIVE_DATE
            };
        } else if(subChargeType === "CONT"){
            items = {
                ...DISCHARGING, ...FIRST_DISCHARGING, ...LAST_DISCHARGING,
                ...GATE_IN, ...COS, ...RECEIVE_DATE
            };
        } else if(subChargeType === "TSS"){
            items = {
                ...FIRST_LEG_ETB, ...FIRST_LEG_ETB_ADD_1, ...FIRST_LEG_ETB_MINUS_1,
                ...FIRST_LEG_ETD, ...FIRST_LEG_ETD_ADD_1, ...FIRST_LEG_ETD_MINUS_1,
                ...FIRST_LEG_DISCHARGING, ...FIRST_LEG_FIRST_DISCHARGING, ...FIRST_LEG_LAST_DISCHARGING
            };
        } else if(subChargeType === "OBS"){
            items = {
                ...RECEIVE_DATE
            };
        }

        return [
            ...(_.map(
                _.toPairs(items), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: key, value: value }
                )
            ) ?? [])
        ];
    }

    const getFSPCountingToBySubChargeType = (chargeType: string, subChargeType: string) => {
        let items:{ [key: string]: string } = {};

        if(subChargeType === "COS"){
            items = {
                ...TO_COS
            };
        } else if(subChargeType === "CSR"){
            items = {
                ...GATE_OUT
            };
        } else if(subChargeType === "EM"){
            items = {
                ...GATE_OUT
            };
        } else if(subChargeType === "EC"){
            items = {
                ...GATE_OUT, ...LOADING
            };
        } else if(subChargeType === "EI"){
            items = {
                ...GATE_OUT
            };
        } else if(subChargeType === "ITM"){
            items = {
                ...DELIVERY_DATE
            };
        } else if(subChargeType === "CONT"){
            items = {
                ...LOADING, ...FIRST_LOADING, ...LAST_LOADING,
                ...GATE_OUT, ...TO_COS
            };
        } else if(subChargeType === "TSS"){
            items = {
                ...SECOND_LEG_ETB, ...SECOND_LEG_ETB_ADD_1, ...SECOND_LEG_ETB_MINUS_1,
                ...SECOND_LEG_ETD, ...SECOND_LEG_ETD_ADD_1, ...SECOND_LEG_ETD_MINUS_1,
                ...SECOND_LEG_LOADING, ...SECOND_LEG_FIRST_LOADING, ...SECOND_LEG_LAST_LOADING
            };
        } else if(subChargeType === "OBS"){
            items = {
                ...ETB, ...ETB_ADD_1, ...ETB_MINUS_1,
                ...ETD, ...ETD_ADD_1, ...ETD_MINUS_1,
                ...LOADING, ...FIRST_LOADING, ...LAST_LOADING,
                ...GATE_CLOSING_TIME
            };
        } else if(subChargeType === "INTERIM" && chargeType === ChargeTypePolicy.CHARGE_TYPE_ITM){
            items = {
                ...DELIVERY_DATE
            };
        } else if(subChargeType === "INTERIM" && chargeType === ChargeTypePolicy.CHARGE_TYPE_GS){
            items = {
                ...LOADING, ...FIRST_LOADING, ...LAST_LOADING,
                ...GATE_OUT, ...TO_COS
            };
        }

        return [
            ...(_.map(
                _.toPairs(items), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: key, value: value }
                )
            ) ?? [])
        ];
    }

    const getFromValueByKey = (chargeType: string, key: string) => {
        const tempDroOpts = getFSPCountingFromByChargeType(chargeType);

        return  tempDroOpts.find(item => item.tagLabel === key)?.value??"";        
    }

    const getFromKeyByValue = (chargeType: string, value: string) => {
        const tempDroOpts = getFSPCountingFromByChargeType(chargeType);

        return  tempDroOpts.find(item => item.value === value)?.tagLabel??"";        
    }

    const getToValueByKey = (chargeType: string, key: string) => {
        const tempDroOpts = getFSPCountingToByChargeType(chargeType);

        return  tempDroOpts.find(item => item.tagLabel === key)?.value??"";        
    }

    const getToKeyByValue = (chargeType: string, value: string) => {
        const tempDroOpts = getFSPCountingToByChargeType(chargeType);

        return  tempDroOpts.find(item => item.value === value)?.tagLabel??"";        
    }

    return {
        getFSPCountingFromByChargeType,
        getFSPCountingToByChargeType,
        getFSPCountingFromBySubChargeType,
        getFSPCountingToBySubChargeType,
        getFromValueByKey,
        getFromKeyByValue,
        getToValueByKey,
        getToKeyByValue        
    }
}