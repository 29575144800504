import * as yup from 'yup';

export const commonCompFspValidation: { [x: string]: any; } = {
    shipmentType: yup.string().required("Missing"),
    effectiveDate: yup.date().required("Missing"),
    chargeType: yup.string().required("Missing"),
    fspUOM: yup.string().required("Missing"),
    firstTierFSP: yup.number().required("Missing"),
    secondTierFSP: yup.number().required("Missing"),
    fspCountingFrom: yup.string().required("Missing"),
    fspCountingTo: yup.string().required("Missing"),
    fspCountingDirection: yup.string().required("Missing"),
    activeInd: yup.string().required("Missing"),
};

export const compFspValidation: { [x: string]: any; } = {
    ...commonCompFspValidation,
};

export const commonCompFspValidationSchema = yup.object().shape(compFspValidation);
