import { EMPTY_COMPANY_MAINTENANCE_MODEL } from 'presentation/model/Company/CompanyMaintenanceModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: CompanyMaintenanceProvider,
    useTracked: useCompanyMaintenanceTracked
} = createContainer(() => useState(EMPTY_COMPANY_MAINTENANCE_MODEL), {concurrentMode: true});
export { CompanyMaintenanceProvider, useCompanyMaintenanceTracked };

