import { CompanyTeuFactorRepoImpl } from "domain/repository/Company/CompanyTeuFactorRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { CompanyTeuFactorVM } from "presentation/viewModel/Company/CompanyTeuFactorVM";
import { useMemo } from "react";

export const useCompanyTeuFactorVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const companyTeuFactorVM = useMemo(() =>
        CompanyTeuFactorVM({
            dispatch: [setCompanyDetailState],
            companyTeuFactorRepo: CompanyTeuFactorRepoImpl(),
        }), [setCompanyDetailState])

    return companyTeuFactorVM
}