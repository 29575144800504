import { ChargeTypePolicy } from "constants/charge/ChargeTypePolicy";
import { DirectionPolicy } from "constants/company/DirectionPolicy";

export const FSPDirectionDroOpts = () => {

    const getDirectionByChargeType = (chargeType: string) => {
        let items = [];

        if (chargeType === ChargeTypePolicy.CHARGE_TYPE_ITM) {
            items.push(DirectionPolicy.FORWARD);
        } else {
            items.push(DirectionPolicy.BACKWARD);
            items.push(DirectionPolicy.FORWARD);
        }

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getDirectionByChargeType        
    }
}