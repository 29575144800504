import { CompanyTeuFactorEntity } from "domain/entity/Company/CompanyTeuFactorEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CompanyTeuFactorRepository } from "./CompanyTeuFactorRepo";

export const CompanyTeuFactorRepoImpl = (): CompanyTeuFactorRepository => {
    const url = '/v1/companyTeuFactor';

    const searchCompTeuFactor = async(companyId: number) : Promise<CompanyTeuFactorEntity[]> => {
        const newUrl = url + '/';
        const result = axiosGetData(companyAxiosInstance, `${newUrl}` + companyId).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    //errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const addCompTeuFactor = async(newData: CompanyTeuFactorEntity) : Promise<Boolean> => {
        const newUrl = url + '/add';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateCompTeuFactor = async(updatedData: CompanyTeuFactorEntity): Promise<CompanyTeuFactorEntity> => {
        const newUrl = url + '/update';
        
        return await axiosPutData(companyAxiosInstance, `${newUrl}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to update company teu factor."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const deleteCompTeuFactor = async(deleteData: CompanyTeuFactorEntity): Promise<Boolean> => {
        const newUrl = url + '/delete';
        const result = axiosPutData(companyAxiosInstance, `${newUrl}`, deleteData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    return {
        searchCompTeuFactor: searchCompTeuFactor,
        addCompTeuFactor: addCompTeuFactor,
        updateCompTeuFactor: updateCompTeuFactor,
        deleteCompTeuFactor: deleteCompTeuFactor
    }
}