import { memo, useEffect, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import CompanyDetailTitleBar from "./CompanyDetailTitleBar";
import CompanyDetailContent from "./CompanyDetailContent";
import CompanyInfoDetailFormPanel from "./LeftPanel/CompanyInfoDetailFormPanel";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import CompanyDetailRejectReasonModal from "./CompanyDetailRejectReasonModal";

const CompanyDetailMaintenance:React.FC = () => {   
    const [, setCompanyDtlState ] = useCompanyDetailTracked();    

    const [ companyState ] = useCompanyMaintenanceTracked();
    const {currentCompany} = companyState;
    
    const memoCompanyDetailTitleBar = useMemo(() => <CompanyDetailTitleBar/>, []);
    const memoCompanyInfoDetailFormPanel = useMemo(() => <CompanyInfoDetailFormPanel/>, []);
    const memoCompanyDetailContent = useMemo(() => <CompanyDetailContent/>, []);    

    useEffect(() => {
        if(currentCompany?.id !== 0){
            setCompanyDtlState(prevState => ({
                ...prevState,
                companyId:  currentCompany?.id              
            }));
        }
        
    }, [currentCompany?.id, setCompanyDtlState])
    return <>
       { <div className="main-comp-wrapper">
            {memoCompanyDetailTitleBar}
            <SliderPanel
                isOpen={true}
                leftSectionWidth={"25%"}
                rightSectionWidth={"75%"}
                leftChildren={memoCompanyInfoDetailFormPanel}
                rightChildren={memoCompanyDetailContent}
            /> 
            <div className='im-charge-data-search-confirm-modal-container'>
                { <CompanyDetailRejectReasonModal/> }
            </div>           
        </div>}
    </>
}

export default memo(CompanyDetailMaintenance)