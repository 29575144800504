import { CompanyChargeSuppleTermsEntity } from "domain/entity/Company/CompanyChargeSuppleTermsEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CompanyChargeSuppleTermsRepository } from "./CompanyChargeSuppleTermsRepo";

export const CompanyChargeSuppleTermsRepoImpl = (): CompanyChargeSuppleTermsRepository => {
    const url = '/v1/compChargeSuppleTerms';

    const searchCompChargeSuppleTerms = async(companyId: number) : Promise<CompanyChargeSuppleTermsEntity[]> => {
        const newUrl = url + '/';
        const result = axiosGetData(companyAxiosInstance, `${newUrl}` + companyId).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    //errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const addCompChargeSuppleTerms = async(newData: CompanyChargeSuppleTermsEntity) : Promise<CompanyChargeSuppleTermsEntity> => {
        const newUrl = url + '/add';        

        return await axiosPostData(companyAxiosInstance, `${newUrl}`, newData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to add company charge supplementary terms."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const updateCompChargeSuppleTerms = async(updatedData: CompanyChargeSuppleTermsEntity): Promise<CompanyChargeSuppleTermsEntity> => {
        const newUrl = url + '/update';
        
        return await axiosPutData(companyAxiosInstance, `${newUrl}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to update company charge supplementary terms."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const batchUpdateCompChgSuppleTerms = async(updateChargeSuppleTerms: CompanyChargeSuppleTermsEntity[]): Promise<Boolean> => {
        const newUrl = url + '/batchUpdate';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, updateChargeSuppleTerms).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteCompChargeSuppleTerms = async(compChgSuppleTermIds: number[]): Promise<Boolean> => {
        const newUrl = url + '/delete';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, compChgSuppleTermIds).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    return {
        searchCompChargeSuppleTerms: searchCompChargeSuppleTerms,
        addCompChargeSuppleTerms: addCompChargeSuppleTerms,
        updateCompChargeSuppleTerms: updateCompChargeSuppleTerms,
        deleteCompChargeSuppleTerms: deleteCompChargeSuppleTerms,
        batchUpdateCompChgSuppleTerms: batchUpdateCompChgSuppleTerms
    }
}