import { CompanyConstant, compCustAssignRequiredFieldList } from "presentation/constant/Company/CompanyConstant";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCustomerAssignmentVM } from "presentation/hook/Company/useCustomerAssignmentVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";

import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CustomerAssignmentFormPanel = () => {   
    const [ companyDtlState ] = useCompanyDetailTracked();
    const customerAssignmentVM = useCustomerAssignmentVM();
    const companyDtlVM = useCompanyDetailVM()
    const messageBarVM = useMessageBarVM(); 
    const COMPANY_DTL_CONST = CompanyConstant.Detail;     
    const [isLoading, setIsLoading] = useState(false);
    const {dynamicOptions} = companyDtlState
    const {isAdd,isEditable, isEditCompDtlInfo, allFormState, isSaveClicked} = companyDtlState.viewState
    const {currentCompanyCust} = companyDtlState.customerAssignmentState

    useEffect(() => {
        if (companyDtlState.viewState.isShowCustAssign) {   
            setIsLoading(true);         
            customerAssignmentVM.loadDropdownOption().then(data=> {
                customerAssignmentVM.onInitDefaultValue();
            });
            setIsLoading(false);
        }        
    }, [customerAssignmentVM, companyDtlState.isShowRightPanel, companyDtlState.viewState.isShowCustAssign]);

    const handleCancelClick = useCallback(() => {
        companyDtlVM.onCancel();
    },[companyDtlVM])

    const handleSaveClick = useCallback(() => {
        if(currentCompanyCust){
            setIsLoading(true);
            
            if(isAdd){
                currentCompanyCust.companyId = companyDtlState.companyId;
            }

            companyDtlVM.onSaveClicked();
            customerAssignmentVM.onSave(currentCompanyCust, isAdd).then((data) => {
                if (data && data["saveCustomerAssignFailed"]) {
                    setIsLoading(false);
                    messageBarVM.showError(data["saveCustomerAssignFailed"]?.toString());
                    return;
                }
                
                companyDtlState.companyId && customerAssignmentVM.initialCustAssignTableData(companyDtlState.companyId).then(()=>{
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                });
            }).catch(()=>{
                setIsLoading(false);
                messageBarVM.showError('Save Data Failed.');
            });
        }
    },[companyDtlState.companyId, companyDtlVM, currentCompanyCust, customerAssignmentVM, isAdd, messageBarVM])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd || isEditable || !isEditCompDtlInfo}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyCust?.operatingTml??''}
                fieldValue={currentCompanyCust?.operatingTml??''}
                fieldLabel={COMPANY_DTL_CONST.OPERATING_TML}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                options={dynamicOptions.custAssignOperatingTmlDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                customerAssignmentVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.OPERATING_TML, allFormState, currentCompanyCust?.operatingTml, customerAssignmentVM, dynamicOptions.custAssignOperatingTmlDropdownOptions, isAdd, isEditCompDtlInfo, isEditable])

    const memoInterCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd || isEditable || !isEditCompDtlInfo}
                isShowOptional={false}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyCust?.intercompanyCode??''}
                fieldValue={currentCompanyCust?.intercompanyCode??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.INTERCOMPANY_CODE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'intercompanyCode'}
                options={dynamicOptions.interCompanyCodeDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compCustAssignRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    customerAssignmentVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.INTERCOMPANY_CODE, allFormState, currentCompanyCust?.intercompanyCode, customerAssignmentVM, dynamicOptions.interCompanyCodeDropdownOptions, isAdd, isEditCompDtlInfo, isEditable, isSaveClicked])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={!isAdd || isEditable || !isEditCompDtlInfo}
                    label={COMPANY_DTL_CONST.EFFECTIVE_DATE}
                    width="180px"
                    date={currentCompanyCust?.effectiveDate??null}
                    fieldName="effectiveDate"
                    errorMessage={allFormState?allFormState["effectiveDate"]:""}
                    onDateChange={customerAssignmentVM.onFieldChange}/>
        </div>
    , [COMPANY_DTL_CONST.EFFECTIVE_DATE, allFormState, currentCompanyCust?.effectiveDate, customerAssignmentVM.onFieldChange, isAdd, isEditCompDtlInfo, isEditable])

    const memoCustomerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isEditCompDtlInfo}
                isShowOptional={false}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={/*currentCompanyCust?.customer?.customerCode??""*/
                    dynamicOptions.customerCodeDropdownOptions?.find(item => Number(item.tagLabel) === currentCompanyCust.customerId)?.value??""
                }
                fieldValue={/*currentCompanyCust?.customer?.customerCode??""*/
                    dynamicOptions.customerCodeDropdownOptions?.find(item => Number(item.tagLabel) === currentCompanyCust.customerId)?.value??""
                }
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_CODE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerId'}                
                options={dynamicOptions.customerCodeDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compCustAssignRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    customerAssignmentVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />            
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_CODE, allFormState, currentCompanyCust.customerId, customerAssignmentVM, dynamicOptions.customerCodeDropdownOptions, isEditCompDtlInfo, isSaveClicked])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd || isEditable || !isEditCompDtlInfo}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyCust?.chargeType??''}
                fieldValue={currentCompanyCust?.chargeType??''}
                fieldLabel={COMPANY_DTL_CONST.CHARGE_TYPE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                options={dynamicOptions.chargeTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                customerAssignmentVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.CHARGE_TYPE, allFormState, currentCompanyCust?.chargeType, customerAssignmentVM, dynamicOptions.chargeTypeDropdownOptions, isAdd, isEditCompDtlInfo, isEditable])

    const memoCurrencyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isEditCompDtlInfo}
                isShowOptional={false}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompanyCust?.currencyCode??''}
                fieldValue={currentCompanyCust?.currencyCode??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CURRENCY_CODE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currencyCode'}
                options={dynamicOptions.currencyCodeDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={compCustAssignRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                customerAssignmentVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />            
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CURRENCY_CODE, allFormState, currentCompanyCust?.currencyCode, customerAssignmentVM, dynamicOptions.currencyCodeDropdownOptions, isEditCompDtlInfo, isSaveClicked])    

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Customer Assignment'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            
            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoOperatingTml}
                            {memoInterCompanyCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoEffectiveDate}
                            {memoCustomerCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoCurrencyCode}
                        </CriteriaItemContainer>                        

                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={()=>handleCancelClick()} />
                        <HPHButton label={isAdd?'Add':isEditable?'Update':'Save'} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />
                    </>
                }
            </div>           
            
        </div>
    )
}
export default memo(CustomerAssignmentFormPanel);