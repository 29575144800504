import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { useChargeSuppleTermsVM } from "presentation/hook/Company/useChargeSuppleTermsVM";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCompanyEmptyPoolVM } from "presentation/hook/Company/useCompanyEmptyPoolVM";
import { useCompanyFspVM } from "presentation/hook/Company/useCompanyFspVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { DialogModal, FieldType, HPHButton, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";

const CompanyDetailRejectReasonModal: React.FC = () => {
    const [companyDtlState] = useCompanyDetailTracked();
    const companyDtlVM = useCompanyDetailVM();
    const companyFspVM = useCompanyFspVM();
    const companyEmptyPoolVM = useCompanyEmptyPoolVM();
    const chargeSuppleTermsVM = useChargeSuppleTermsVM();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);
    const {rejectReason, dataType, isShowRejectModal} = companyDtlState.viewState;

    const rejectCompFsp = useCallback(() => {
        setIsLoading(true);
        companyFspVM.onReject(companyDtlState.companyFspState.selectedDatas, rejectReason).then(() => {
            companyDtlState.companyId && companyFspVM.initialFspTableData(companyDtlState.companyId).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
    }, [companyDtlState.companyFspState.selectedDatas, companyDtlState.companyId, companyFspVM, messageBarVM, rejectReason])

    const rejectCompEmptyPool = useCallback(() => {
        setIsLoading(true);
        companyEmptyPoolVM.onReject(companyDtlState.companyEmptyPoolState.selectedDatas,rejectReason).then(() => {
            companyDtlState.companyId && companyEmptyPoolVM.initialEmptyPoolTableData(companyDtlState.companyId).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
    }, [companyDtlState.companyEmptyPoolState.selectedDatas, companyDtlState.companyId, companyEmptyPoolVM, messageBarVM, rejectReason])

    const rejectCompChgSuppleTerm = useCallback(() => {
        setIsLoading(true);
        chargeSuppleTermsVM.onReject(companyDtlState.chargeSuppleTermsState.selectedDatas,rejectReason).then(() => {
            companyDtlState.companyId && chargeSuppleTermsVM.initialChgSuppleTermsTableData(companyDtlState.companyId).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
    }, [chargeSuppleTermsVM, companyDtlState.chargeSuppleTermsState.selectedDatas, companyDtlState.companyId, messageBarVM, rejectReason])

    const onRejectBtn = useCallback(() => {
        if(dataType !== "" || dataType.length > 0){
            if(dataType === "FSP"){
                rejectCompFsp();                
            } else if(dataType === "EMPTY_POOL"){
                rejectCompEmptyPool();
            } else if(dataType === "CHG_SUPPLE_TERMS"){
                rejectCompChgSuppleTerm();
            }
        }
    },[rejectCompChgSuppleTerm, rejectCompEmptyPool, rejectCompFsp, dataType])

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"395px",marginBottom:"24px"}} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={rejectReason??''}
                fieldValue={rejectReason??''}
                fieldLabel={CompanyConstant.Detail.REJECT_REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'rejectReason'}
                maxLength={200}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => companyDtlVM.onRejectReasonTextChange(fieldValue && fieldValue.toString())} 
                requiredFieldList={['rejectReason']}                    />
        </div>
    , [companyDtlVM, rejectReason])
    


    return <><DialogModal style={{  minWidth:'650px', minHeight:'650px' }}
    appendTo='self'
    showButton={false}
    visible={isShowRejectModal}
    positions="center"
    resizable={false}
    header={
        <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">Reject Reason</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={companyDtlVM.closeRejectReasonModal} tooltipDisable={true}/>
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                    {memoRejectReason}
                </CriteriaItemContainer> 

            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton disabled={rejectReason?.length===0} label={'Reject'} size={'Small'} theme={'Primary'} onClick={onRejectBtn}/>
            </div>
        }
    />
        {isLoading && <Loader Indicator="Spinner" size="Medium" />} 
    </>;
};

export default memo(CompanyDetailRejectReasonModal);