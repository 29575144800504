import { ContainerMovementPolicy } from "constants/company/ContainerMovementPolicy";

export const CntrMovementDroOpts = () => {

    const getDroOptsModel = (showMovementItem: boolean) => {
        let ret: string[] = [];
        
        if (showMovementItem) {
            ret.push(ContainerMovementPolicy.CNTR_MOVEMENT_IB);
            ret.push(ContainerMovementPolicy.CNTR_MOVEMENT_OB);
            ret.push(ContainerMovementPolicy.CNTR_MOVEMENT_TS);
        }

        return [
            ...(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    const getInBoundMovementDroOptsModel = () => {
        let ret: string[] = [ContainerMovementPolicy.CNTR_MOVEMENT_IB];

        return [
            ...(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    const getOutBoundMovementDroOptsModel = () => {
        let ret: string[] = [ContainerMovementPolicy.CNTR_MOVEMENT_OB];
        
        return [
            ...(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getDroOptsModel,
        getInBoundMovementDroOptsModel,
        getOutBoundMovementDroOptsModel
    }
}