import { CompanyTerminalRepoImpl } from "domain/repository/Company/CompanyTerminalRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { CompanyTerminalVM } from "presentation/viewModel/Company/CompanyTerminalVM";
import { useMemo } from "react";

export const useCompanyTerminalVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const companyTerminalVM = useMemo(() =>
        CompanyTerminalVM({
            dispatch: [setCompanyDetailState],
            companyTerminalRepo: CompanyTerminalRepoImpl()
        }), [setCompanyDetailState])

    return companyTerminalVM
}