import { TariffNatureEntity } from "../TariffNature/TariffNatureEntity"
import { CompanyEntity } from "./CompanyEntity"

export interface CompanyTariffNatureEntity {
    id: number | null,
    versionIdentifier: {
        version?: string
    },
    companyId: number | null,
    tariffNatureId: number | null,
    company: CompanyEntity | null,
    tariffNature: TariffNatureEntity | null
    
    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_TARIFF_NATURE_ENTITY : CompanyTariffNatureEntity = {
    id: null,
    versionIdentifier: {},
    companyId: null,
    tariffNatureId: null,
    company: null,
    tariffNature: null
}