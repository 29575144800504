
export interface CompanyTeuFactorEntity {
    id: number | null,
    versionIdentifier: {
        version?: string
    },
    companyId: number | null,
    cntrSize: number | null,
    teuFactor: number | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_TEU_FACTOR_ENTITY : CompanyTeuFactorEntity = {
    id: null,
    versionIdentifier: {},
    companyId: null,
    cntrSize: null,
    teuFactor: null
}