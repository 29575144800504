import * as yup from 'yup';

export const commonCompEmptyPoolValidation: { [x: string]: any; } = {
    poolType: yup.string().required("Missing"),
    effectiveDate: yup.date().required("Missing"),
    poolSize: yup.number().required("Missing"),
    uom: yup.string().required("Missing"),
    calculateBy: yup.string().required("Missing"),
    activeInd: yup.string().required("Missing"),
};

export const commonCompEmptyPoolValidationSchema = yup.object().shape(commonCompEmptyPoolValidation);
