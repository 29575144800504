import { EMPTY_COMPANY_DETAIL_MODEL } from 'presentation/model/Company/CompanyDetailModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: CompanyDetailProvider,
    useTracked: useCompanyDetailTracked
} = createContainer(() => useState(EMPTY_COMPANY_DETAIL_MODEL), {concurrentMode: true});
export { CompanyDetailProvider, useCompanyDetailTracked };

