import { CompanyEntity } from "./CompanyEntity"
import { CustomerEntity } from "./CustomerEntity"

export interface CompanyCustomerEntity {
    id: number | null,
    versionIdentifier: {
        version?: string
    },
    companyId: number | null,
    customerId: number | null,
    chargeType: string | null,
    operatingTml: string | null,
    effectiveDate: Date | null,
    intercompanyCode: string | null,
    currencyCode: string | null,
    emailAddress: string | null,
    company: CompanyEntity | null,
    customer: CustomerEntity | null,    
    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_CUSTOMER_ENTITY : CompanyCustomerEntity = {
    id: null,
    versionIdentifier: {},
    companyId: null,
    customerId: null,
    chargeType: null,
    operatingTml: null,
    effectiveDate: null,
    intercompanyCode: null,
    currencyCode: null,
    emailAddress: null,
    company: null,
    customer: null,    
}