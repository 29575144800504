export interface CompanyEnabledSearchCriteria {   
    all: boolean,
    companyCode: boolean,
    companyName: boolean,
    activeInd: boolean,
    
    [key: string]: boolean,
}

export const DEFAULT_ENABLED_COMPANY_SEARCH_CRITERIA : CompanyEnabledSearchCriteria = {
    all: true,
    companyCode: true,
    companyName: true,
    activeInd: true
}