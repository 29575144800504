import { ActiveInd } from "constants/ActiveInd";
import _ from "lodash";

export const ActiveIndDroOpts = () => {

    const getDroOptsModel = () => {
        let ret: string[] = [ActiveInd.Y, ActiveInd.N];

        return [
            ...(_.map(
                _.toPairs(ret), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: value, value: value }
                )
            ) ?? [])];
    }

    return {
        getDroOptsModel        
    }
}