import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";

import { CompanyEmptyPoolRepository } from "./CompanyEmptyPoolRepo";
import { CompanyEmptyPoolEntity } from "domain/entity/Company/CompanyEmptyPoolEntity";

export const CompanyEmptyPoolRepoImpl = (): CompanyEmptyPoolRepository => {
    const url = '/v1/companyEmptyPool';

    const searchCompEmptyPool = async(companyId: number) : Promise<CompanyEmptyPoolEntity[]> => {
        const newUrl = url + '/';
        const result = axiosGetData(companyAxiosInstance, `${newUrl}` + companyId).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    //errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const addCompEmptyPool = async(newData: CompanyEmptyPoolEntity) : Promise<CompanyEmptyPoolEntity> => {
        const newUrl = url + '/add';        

        return await axiosPostData(companyAxiosInstance, `${newUrl}`, newData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to add company empty pool."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const updateCompEmptyPool = async(updatedData: CompanyEmptyPoolEntity): Promise<CompanyEmptyPoolEntity> => {
        const newUrl = url + '/update';        

        return await axiosPutData(companyAxiosInstance, `${newUrl}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to update company empty pool."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const batchUpdateCompEmptyPool = async(updateCompEmptyPools: CompanyEmptyPoolEntity[]): Promise<ResponseEntity> => {
        const newUrl = url + '/batchUpdate';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, updateCompEmptyPools).then(res => {
            //const data = res.data;
            return res
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteCompEmptyPool = async(compEmptyPoolIds: number[]): Promise<Boolean> => {
        const newUrl = url + '/delete';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, compEmptyPoolIds).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    return {
        searchCompEmptyPool: searchCompEmptyPool,
        addCompEmptyPool: addCompEmptyPool,
        updateCompEmptyPool: updateCompEmptyPool,
        deleteCompEmptyPool: deleteCompEmptyPool,
        batchUpdateCompEmptyPool: batchUpdateCompEmptyPool
    }
}