import { CompanyTariffNatureRepoImpl } from "domain/repository/Company/CompanyTariffNatureRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { CompanyTariffNatureVM } from "presentation/viewModel/Company/CompanyTariffNatureVM";
import { useMemo } from "react";

export const useCompanyTariffNatureVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const companyTariffNatureVM = useMemo(() =>
        CompanyTariffNatureVM({
            dispatch: [setCompanyDetailState],
            companyTariffNatureRepo: CompanyTariffNatureRepoImpl()
        }), [setCompanyDetailState])

    return companyTariffNatureVM
}