import { ContainerStatusPolicy } from "constants/charge/ContainerStatusPolicy";

export const CntrStatusDroOpts = () => {

    const getDroOptsModel = () => {
        let ret = ContainerStatusPolicy().allCntrStatus; 

        return [
            ...(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    const getDroOptsModelByChgType = (chargeType: string) => {
        let ret = ContainerStatusPolicy().getCntrStatusFromChargeType(chargeType);

        return [
            ...(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    const getDroOptsModelByMovementAndChgType = (chargeType: string, cntrMovement: string) => {
        let ret = ContainerStatusPolicy().getCntrStatusByMovementAndChgType(chargeType, cntrMovement);

        return [
            ...(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getDroOptsModel,
        getDroOptsModelByChgType,
        getDroOptsModelByMovementAndChgType,
    }
}