import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CompanyDetailProvider } from "presentation/store/Company/CompanyDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import CompanyDetailMaintenance from "presentation/view/section/Company/Detail/CompanyDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const CompanyDetailContMain = () => {
    return <ANAInfoWrapper permission={Permission.COMPANY_DETAIL_MAINTENANCE}>
        <MessageBarWrapper>
            <CompanyDetailProvider>
                <GridStyles/>
                <CompanyDetailMaintenance/>
            </CompanyDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default CompanyDetailContMain;