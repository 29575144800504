import { SupplementaryTermsPolicy } from "constants/company/SupplementaryTermsPolicy";
import _ from "lodash";

export const SupplementaryTermsDroOpts = () => {

    const getSuppleTermsDroOptsModel = () => {
        let ret: string[] = [
            SupplementaryTermsPolicy.IN_YARD_DAY_LIMIT,
            SupplementaryTermsPolicy.LATE_ARRIVAL_GRACE,
            SupplementaryTermsPolicy.INTENSIVE_SUR_PERC,
            SupplementaryTermsPolicy.DK_BERTH_GRACE_PERIOD,
            SupplementaryTermsPolicy.HANDLE_SHORTSEA_AS,
            SupplementaryTermsPolicy.RAIL_IMO_SUR,
            SupplementaryTermsPolicy.RAIL_RFR_SUR,
            SupplementaryTermsPolicy.RAIL_OW_SUR,
            SupplementaryTermsPolicy.BILLING_CYCLE,
            SupplementaryTermsPolicy.BILLING_TYPE_INBOUND_NON_CY,
            SupplementaryTermsPolicy.BILLING_TYPE_OUTBOUND_NON_CY
        ]

        return [
            ...(_.map(
                _.toPairs(ret), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: key, value: value }
                )
            ) ?? [])];
    }

    return {
        getSuppleTermsDroOptsModel
    }
}