import { PoolCalculateByPolicy } from "constants/company/PoolCalculateByPolicy";

export const PoolCalculateByDroOpts = () => {

    const getPoolCalculateByItemList = () => {
        let items = [];

        items.push(PoolCalculateByPolicy.DAILY);
        items.push(PoolCalculateByPolicy.MONTHLY);

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
            })) ?? [])];
    }

    return {
        getPoolCalculateByItemList        
    }
}