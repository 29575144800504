import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CompanyTerminalRepoImpl } from "domain/repository/Company/CompanyTerminalRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { CompanyDetailVM } from "presentation/viewModel/Company/CompanyDetailVM";
import { useMemo } from "react";

export const useCompanyDetailVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const [, setCompanyMaintenanceState] = useCompanyMaintenanceTracked();

    const companyDetailVM = useMemo(() =>
        CompanyDetailVM({
            dispatch: [setCompanyDetailState],
            companyMainDispatch: [setCompanyMaintenanceState],
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            companyTerminalRepo: CompanyTerminalRepoImpl(),
            searchPreferenceRepo: SearchPreferenceRepoImpl(),            
            masterDataRepo: MasterDataRepoImpl(),
        }), [setCompanyDetailState, setCompanyMaintenanceState])

    return companyDetailVM
}