export interface CompanyFspEntity {
    id: number,
    versionIdentifier: {
        version?: string
    },
    companyId: number | null,
    operatingTml: string | null,
    effectiveDate: Date | null,
    shipmentType: string | null,
    consortiumCode: string | null,
    serviceCode: string | null,
    firstTierFSP: number | null,
    secondTierFSP: number | null,
    fspUOM: string | null,
    graceHour: number | null,
    graceMinute: number | null,
    countingFromCutoffHour:  number | null,
    countingFromCutoffMinute:  number | null,
    fspCountingFrom: string | null,
    fspCountingTo: string | null,
    fspCountingDirection: string | null,
    status: string | null,
    chargeType: string | null,
    subChargeType: string | null,
    remarks: string | null,
    rejectReason: string | null,
    confirmedDate: Date | null,
    cntrStatus: string | null,
    applyCalendarAdj: string | null,
    cntrMovement: string | null,
    priority:  number | null,
    owner: string | null,
    forwarderCode: string | null,
    forwarderReference:  string | null, 
    activeInd: string | null,
    cargoCode: string | null,
    vesselCode: string | null,
    voyageCode: string | null,
    handlingTerminal: string | null,
    fspCountingFromVal: string | null,
    fspCountingToVal: string | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_FSP_ENTITY : CompanyFspEntity = {
    id: 0,
    versionIdentifier: {},
    companyId: null,
    operatingTml: null,
    effectiveDate: null,
    shipmentType: null,
    consortiumCode: null,
    serviceCode: null,
    firstTierFSP: null,
    secondTierFSP: null,
    fspUOM: null,
    graceHour: null,
    graceMinute: null,
    countingFromCutoffHour: null,
    countingFromCutoffMinute: null,
    fspCountingFrom: null,
    fspCountingTo: null,
    fspCountingDirection: null,
    status: "NEW",
    chargeType: null,
    subChargeType: null,
    remarks: null,
    rejectReason: null,
    confirmedDate: null,
    cntrStatus: null,
    applyCalendarAdj: null,
    cntrMovement: null,
    priority: null,
    owner: null,
    forwarderCode: null,
    forwarderReference: null,
    activeInd: "Y",
    cargoCode: null,
    vesselCode: null,
    voyageCode: null,
    handlingTerminal: null,
    fspCountingFromVal: null,
    fspCountingToVal: null
}