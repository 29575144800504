import * as yup from 'yup';

export const commonCompChgSuppleTermValidation_1: { [x: string]: any; } = {    
    effectiveDate: yup.date().required("Missing"),
    supplementaryTerms: yup.string().required("Missing"),
    value: yup.number().required("Missing"),
    activeInd: yup.string().required("Missing")
};

export const compChgSuppleTermValidation_1: { [x: string]: any; } = {
    ...commonCompChgSuppleTermValidation_1,
};

export const commonCompChgSuppleTermValidationSchema_1 = yup.object().shape(compChgSuppleTermValidation_1);


export const commonCompChgSuppleTermValidation_2: { [x: string]: any; } = {
    effectiveDate: yup.date().required("Missing"),
    supplementaryTerms: yup.string().required("Missing"),
    supplementaryTermsRef: yup.string().required("Missing"),
    activeInd: yup.string().required("Missing")
};

export const compChgSuppleTermValidation_2: { [x: string]: any; } = {
    ...commonCompChgSuppleTermValidation_2,
};

export const commonCompChgSuppleTermValidationSchema_2 = yup.object().shape(compChgSuppleTermValidation_2);
