import * as yup from 'yup';

export const commonCompCustAssignValidation: { [x: string]: any; } = {
    intercompanyCode: yup.string().required("Missing"),
    effectiveDate: yup.date().required("Missing"),
    customerId: yup.number().required("Missing"),
    currencyCode: yup.string().required("Missing"),      
};

export const commonCompCustAssignValidationSchema = yup.object().shape(commonCompCustAssignValidation);
