import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { CustomerAssignmentRepoImpl } from "domain/repository/Company/CustomerAssignmentRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { CustomerAssignmentVM } from "presentation/viewModel/Company/CustomerAssignmentVM";
import { useMemo } from "react";

export const useCustomerAssignmentVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const customerAssignmentVM = useMemo(() =>
        CustomerAssignmentVM({
            dispatch: [setCompanyDetailState],
            customerAssignmentRepo: CustomerAssignmentRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            searchPreferenceRepo: SearchPreferenceRepoImpl(),            
            masterDataRepo: MasterDataRepoImpl(),
        }), [setCompanyDetailState])

    return customerAssignmentVM
}