import { CompanyEmptyPoolRepoImpl } from "domain/repository/Company/CompanyEmptyPoolRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { CompanyEmptyPoolVM } from "presentation/viewModel/Company/CompanyEmptyPoolVM";
import { useMemo } from "react";

export const useCompanyEmptyPoolVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const companyEmptyPoolVM = useMemo(() =>
        CompanyEmptyPoolVM({
            dispatch: [setCompanyDetailState],
            companyEmptyPoolRepo: CompanyEmptyPoolRepoImpl()
        }), [setCompanyDetailState])

    return companyEmptyPoolVM
}