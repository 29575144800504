import { CompanyCustomerEntity } from "domain/entity/Company/CompanyCustomerEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CustomerAssignmentRepository } from "./CustomerAssignmentRepo";

export const CustomerAssignmentRepoImpl = (): CustomerAssignmentRepository => {
    const url = '/v1/customerAssignment';

    const searchCustomerAssigment = async(companyId: number) : Promise<CompanyCustomerEntity[]> => {
        const newUrl = url + '/';
        const result = axiosGetData(companyAxiosInstance, `${newUrl}` + companyId).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    //errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const addCustomerAssigment = async(newData: CompanyCustomerEntity) : Promise<CompanyCustomerEntity> => {
        const newUrl = url + '/add';

        return await axiosPostData(companyAxiosInstance, `${newUrl}`, newData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to add customer assignment."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const updateCustomerAssigment = async(updatedData: CompanyCustomerEntity): Promise<CompanyCustomerEntity> => {
        const newUrl = url + '/update';

        return await axiosPutData(companyAxiosInstance, `${newUrl}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to update customer assignment."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const deleteCustomerAssigment = async(compCustIds: number[]): Promise<Boolean> => {
        const newUrl = url + '/delete';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, compCustIds).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    return {
        searchCustomerAssigment: searchCustomerAssigment,
        addCustomerAssigment: addCustomerAssigment,
        updateCustomerAssigment: updateCustomerAssigment,
        deleteCustomerAssigment: deleteCustomerAssigment
    }
}