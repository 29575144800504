import { OpsLineEntity } from "domain/entity/OpsLine/OpsLineEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { OpsLineRepository } from "./OpsLineRepo";
import _ from "lodash";

export const OpsLineRepoImpl = ():OpsLineRepository => {
    const url = '/v1/opsLine'
    const getAllOpsLines = async() : Promise<OpsLineEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url,[]).then(res => {

            let opsLines: OpsLineEntity[] = [];
            if(res.data && res.data.length > 0){
               opsLines = _.uniqBy(res.data, 'opsLine') as unknown as OpsLineEntity[]
            }
            
            return  opsLines;
        }).catch(err => {
            return [];
        })
    }

    return{
        getAllOpsLines: getAllOpsLines
    }
}