import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";

import { CompanyTerminalRepository } from "./CompanyTerminalRepo";
import { CompanyTerminalEntity } from "domain/entity/Company/CompanyTerminalEntity";

export const CompanyTerminalRepoImpl = (): CompanyTerminalRepository => {
    const url = '/v1/companyTerminal';

    const searchCompanyTerminal = async(companyId: number) : Promise<CompanyTerminalEntity[]> => {
        const newUrl = url + '/';
        const result = axiosGetData(companyAxiosInstance, `${newUrl}` + companyId).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    //errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const addCompanyTerminal = async(newData: CompanyTerminalEntity) : Promise<Boolean> => {
        const newUrl = url + '/add';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateCompanyTerminal = async(updatedData: CompanyTerminalEntity): Promise<Boolean> => {
        const newUrl = url + '/update';
        const result = axiosPutData(companyAxiosInstance, `${newUrl}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const saveCompanyTerminal = async(saveDatas: CompanyTerminalEntity[]): Promise<Boolean> => {
        const newUrl = url + '/save';
        
        return await axiosPostData(companyAxiosInstance, `${newUrl}`, saveDatas).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to save contract terminal."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const deleteCompanyTerminal = async(deleteData: CompanyTerminalEntity): Promise<Boolean> => {
        const newUrl = url + '/delete';
        const result = axiosPutData(companyAxiosInstance, `${newUrl}`, deleteData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    return {
        searchCompanyTerminal: searchCompanyTerminal,
        addCompanyTerminal: addCompanyTerminal,
        updateCompanyTerminal: updateCompanyTerminal,
        deleteCompanyTerminal: deleteCompanyTerminal,
        saveCompanyTerminal: saveCompanyTerminal
    }
}