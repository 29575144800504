import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { CustomerVM } from "presentation/viewModel/Company/CustomerVM";
import { useMemo } from "react";

export const useCustomerVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const customerVM = useMemo(() =>
        CustomerVM({
            dispatch: [setCompanyDetailState],
            customerRepo: CustomerRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl()
        }), [setCompanyDetailState])

    return customerVM
}