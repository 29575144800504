import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { memo, useMemo } from "react";
import CompanyFspFormPanel from "./CompanyFspFormPanel";
import ChargeSuppleTermsFormPanel from "./ChargeSuppleTermsFormPanel";
import CompanyEmptyPoolFormPanel from "./CompanyEmptyPoolFormPanel";
import CompanyTeuFactorFormPanel from "./CompanyTeuFactorFormPanel";
import CustomerAssignmentFormPanel from "./CustomerAssignmentFormPanel";
import CustomerInformationFormPanel from "./CustomerInformationFormPanel";

const CompanyDetailFormPanel = () => {   
    const [ companyDtlState ] = useCompanyDetailTracked();
    const {isShowCustAssign, isShowFactor,isShowFsp, isShowEmptyPool, isShowSuppleTerms, isShowCustInfo} = companyDtlState.viewState
    
    const memoCompanyFspForm = useMemo(() => {
        return <CompanyFspFormPanel/>
    },[])

    const memoChgSuppleTermsForm = useMemo(() => {
        return <ChargeSuppleTermsFormPanel/>
    },[])

    const memoEmptyPoolForm = useMemo(() => {
        return <CompanyEmptyPoolFormPanel/>
    },[])

    const memoTeuFactorForm = useMemo(() => {
        return <CompanyTeuFactorFormPanel/>
    },[])

    const memoCustomerAssignForm = useMemo(() => {
        return <CustomerAssignmentFormPanel/>
    },[])

    const memoCustomerInfoForm = useMemo(() => {
        return <CustomerInformationFormPanel/>
    },[])

    return (
        <>
            {isShowFsp && <>{memoCompanyFspForm}</>}
            {isShowSuppleTerms && <>{memoChgSuppleTermsForm}</>}
            {isShowCustAssign && <>{memoCustomerAssignForm}</>}
            {isShowEmptyPool && <>{memoEmptyPoolForm}</>}
            {isShowFactor && <>{memoTeuFactorForm}</>}
            {isShowCustInfo && <>{memoCustomerInfoForm}</>}
        </>
    )
}
export default memo(CompanyDetailFormPanel);