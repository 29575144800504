import { RefObject } from "react";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
import { CompanyEntity, EMPTY_COMPANY_ENTITY } from "domain/entity/Company/CompanyEntity";
import { CompanyFspEntity, EMPTY_COMPANY_FSP_ENTITY } from "domain/entity/Company/CompanyFspEntity";
import { CompanyTariffNatureEntity } from "domain/entity/Company/CompanyTariffNatureEntity";
import { CompanyTerminalEntity } from "domain/entity/Company/CompanyTerminalEntity";
import { CompanyChargeSuppleTermsEntity, EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY } from "domain/entity/Company/CompanyChargeSuppleTermsEntity";
import { CompanyEmptyPoolEntity, EMPTY_COMPANY_EMPTY_POOL_ENTITY } from "domain/entity/Company/CompanyEmptyPoolEntity";
import { CompanyCustomerEntity, EMPTY_COMPANY_CUSTOMER_ENTITY } from "domain/entity/Company/CompanyCustomerEntity";
import { CompanyCustomerInfoEntity } from "domain/entity/Company/CompanyCustomerInfoEntity";
import { CompanyTeuFactorEntity, EMPTY_COMPANY_TEU_FACTOR_ENTITY } from "domain/entity/Company/CompanyTeuFactorEntity";
import { TerminalEntity } from "domain/entity/Terminal/TerminalEntity";
import { TariffNatureEntity } from "domain/entity/TariffNature/TariffNatureEntity";
import { CustomerEntity, EMPTY_CUSTOMER_ENTITY } from "domain/entity/Company/CustomerEntity";

export interface CompanyDetailViewChangeState extends BaseViewChangeSate {
    editingHeader: CompanyEntity,   
    isShowHeaderInfo: boolean,
    isShowDetailInfo: boolean,    
    isShowFsp: boolean,
    isShowEmptyPool: boolean,
    isShowSuppleTerms: boolean,
    isShowCustAssign: boolean,
    isShowCustInfo: boolean,
    isShowFactor: boolean,
    isShowRejectModal: boolean,
    isEditCompDtlInfo: boolean,
    rejectReason: string,
    isShowDeleteModal: boolean,
    dataType: "FSP" | "EMPTY_POOL" | "CHG_SUPPLE_TERMS" | "CUSTOMER_ASSIGN" | ""
}

export interface TeuFactorViewChangeState {    
    selectedDatas: CompanyTeuFactorEntity[],
    currentCompTeuFactor: CompanyTeuFactorEntity
}

export interface CompanyFspViewChangeState {    
    selectedDatas: CompanyFspEntity[],    
    currentCompanyFsp: CompanyFspEntity
}

export interface CompanyEmptyPoolViewChangeState {    
    selectedDatas: CompanyEmptyPoolEntity[],
    currentCompEmptyPool: CompanyEmptyPoolEntity 
}

export interface ChargeSuppleTermsViewChangeState {
    isDisableSuppleTermRef: boolean,
    isDisableCntrInfo: boolean,
    isDisableChargeInfo: boolean,
    isDisableModality: boolean,
    validateSchemaType: "1" | "2",
    selectedDatas: CompanyChargeSuppleTermsEntity[],
    currentCompChgSuppleTerm: CompanyChargeSuppleTermsEntity
}

export interface CustomerAssignmentViewChangeState {    
    selectedDatas: CompanyCustomerEntity[],
    currentCompanyCust: CompanyCustomerEntity
}

export interface CompanyTariffNatureState {    
    selectedTariffNatures: TariffNatureEntity[]
}

export interface CompanyTerminalState {    
    selectedTerminals: TerminalEntity[]  
}

export interface CustomerInformationViewChangeState {    
    currentCustomer: CustomerEntity
}

export interface CompanyDetailDropdownOptions {
    tariffNatureDropdownOptions: DropdownProps[],
    contractTmlDropdownOptions: DropdownProps[],    
    operatingTmlDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],    
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },    
    consortiumCodeDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    cargoCodeDropdownOptions: DropdownProps[],
    fspCountingFromDropdownOptions: DropdownProps[],
    fspCountingToDropdownOptions: DropdownProps[],
    directionDropdownOptions: DropdownProps[],
    suppleTermsDropdownOptions: DropdownProps[],
    suppleTermsRefDropdownOptions: DropdownProps[],
    modalityDropdownOptions: DropdownProps[],
    interCompanyCodeDropdownOptions: DropdownProps[],
    customerCodeDropdownOptions: DropdownProps[],
    currencyCodeDropdownOptions: DropdownProps[],
    regionDropdownOptions: DropdownProps[],
    sizeDropdownOptions: DropdownProps[],
    shipmentTypeDropdownOptions: DropdownProps[],
    opsLineDropdownOptions: DropdownProps[],
    forwardCodeDropdownOptions: DropdownProps[],
    cntrMovementDropdownOptions: DropdownProps[],
    cntrStatusDropdownOptions: DropdownProps[],    
    activeIndDropdownOptions: DropdownProps[],
    fspChargeTypeDropdownOptions: DropdownProps[],
    fspSubChargeTypeDropdownOptions: { [key: string]: DropdownProps[] }, 
    billingCycleDropdownOptions: DropdownProps[],
    shiftCodeDropdownOptions: DropdownProps[],
    poolTypeDropdownOptions: DropdownProps[],
    poolCalculateByDropdownOptions: DropdownProps[],
    poolUomDropdownOptions: DropdownProps[],
    fspUomDropdownOptions: DropdownProps[],
    calendarAdjDropdownOptions: DropdownProps[],
    custAssignOperatingTmlDropdownOptions: DropdownProps[]
}

export interface CompanyDetailModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: CompanyDetailDropdownOptions,
    viewState: CompanyDetailViewChangeState,
    isShowCloseIcon: boolean,
    allFormState: {[key:string]:string},
    companyId: number,
    companyFsps: CompanyFspEntity[],
    companyTariffNatures: CompanyTariffNatureEntity[],
    companyTerminals: CompanyTerminalEntity[],
    compChargeSuppleTerms: CompanyChargeSuppleTermsEntity [],
    companyEmptyPools: CompanyEmptyPoolEntity [],
    companyTeuFactors: CompanyTeuFactorEntity [],
    companyCustomers: CompanyCustomerEntity [],
    companyCustomerInfos: CompanyCustomerInfoEntity [],
    companyTeuFactorState: TeuFactorViewChangeState,
    companyFspState:  CompanyFspViewChangeState,
    companyEmptyPoolState:  CompanyEmptyPoolViewChangeState,
    chargeSuppleTermsState:  ChargeSuppleTermsViewChangeState,
    customerAssignmentState:  CustomerAssignmentViewChangeState,
    companyTariffNatureState: CompanyTariffNatureState,
    companyTerminalState: CompanyTerminalState,
    customerInformationState: CustomerInformationViewChangeState
}

export const EMPTY_COMPANY_DETAIL_MODEL: CompanyDetailModel = {
    isLoading: false,
    isShowRightPanel: false,
    isFilterSearchCriteria: false,
    isEditSearchCriteria: false,
    isShowCloseIcon: true,
    searchCounter: 0,
    allFormState: {},
    companyId: 0,
    companyFsps: [],
    companyTariffNatures: [],
    companyTerminals: [],
    compChargeSuppleTerms:  [],
    companyEmptyPools:  [],
    companyTeuFactors:  [],
    companyCustomers:  [],
    companyCustomerInfos: [],
    dynamicOptions: {
        tariffNatureDropdownOptions: [],
        contractTmlDropdownOptions: [],        
        operatingTmlDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        fspChargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        consortiumCodeDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        cargoCodeDropdownOptions: [],
        fspCountingFromDropdownOptions: [],
        fspCountingToDropdownOptions: [],
        directionDropdownOptions: [],
        suppleTermsDropdownOptions: [],
        suppleTermsRefDropdownOptions: [],
        modalityDropdownOptions: [],
        interCompanyCodeDropdownOptions: [],
        customerCodeDropdownOptions: [],
        currencyCodeDropdownOptions: [],
        regionDropdownOptions: [],
        sizeDropdownOptions: [],
        shipmentTypeDropdownOptions: [],
        opsLineDropdownOptions: [],
        forwardCodeDropdownOptions: [],
        cntrMovementDropdownOptions: [],
        cntrStatusDropdownOptions: [],        
        activeIndDropdownOptions: [],
        fspSubChargeTypeDropdownOptions: { },
        billingCycleDropdownOptions: [],
        shiftCodeDropdownOptions: [],
        poolTypeDropdownOptions: [],
        poolCalculateByDropdownOptions: [],
        poolUomDropdownOptions: [],
        fspUomDropdownOptions: [],
        calendarAdjDropdownOptions: [],
        custAssignOperatingTmlDropdownOptions: [],
    },
    viewState: {
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record        
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record        
        editingHeader: EMPTY_COMPANY_ENTITY,
        lastEditRowId: "",
        isSelected: false,
        allFormState: {},
        isShowHeaderInfo: false,
        isShowDetailInfo: false,        
        isShowFsp: false,
        isShowEmptyPool: false,
        isShowSuppleTerms: false,
        isShowCustAssign: false,
        isShowCustInfo: false,
        isShowFactor: false,
        isEditCompDtlInfo: false,
        isShowRejectModal: false,
        rejectReason: "",
        dataType: "",
        isShowDeleteModal: false,

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        },
    },

    companyTeuFactorState: {
        selectedDatas: [],
        currentCompTeuFactor: EMPTY_COMPANY_TEU_FACTOR_ENTITY
    },
    companyFspState: {
        selectedDatas: [],
        currentCompanyFsp: EMPTY_COMPANY_FSP_ENTITY
    },
    companyEmptyPoolState: {
        selectedDatas: [],
        currentCompEmptyPool: EMPTY_COMPANY_EMPTY_POOL_ENTITY
    },
    chargeSuppleTermsState: {
        isDisableSuppleTermRef: true,
        isDisableCntrInfo: true,
        isDisableChargeInfo: true,
        isDisableModality: true,
        validateSchemaType: "1",
        selectedDatas: [],
        currentCompChgSuppleTerm: EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY
    },
    customerAssignmentState: {
        selectedDatas: [],
        currentCompanyCust: EMPTY_COMPANY_CUSTOMER_ENTITY
    },
    companyTariffNatureState: {
        selectedTariffNatures: []
    },
    companyTerminalState: {
        selectedTerminals: []
    },
    customerInformationState: {
        currentCustomer: EMPTY_CUSTOMER_ENTITY
    }
}