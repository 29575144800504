export const FSPCountingKeyPolicy = ({
    ETB: "ETB",
    ETB_ADD_1: "ETB+1",
    ETB_MINUS_1: "ETB-1",
    ETD: "ETD",
    ETD_ADD_1: "ETD+1",
    ETD_MINUS_1: "ETD-1",
    DISCHARGE: "DISCHARGE",
    FIRST_DISCHARGE: "FIRST_DISCHARGE",
    LAST_DISCHARGE: "LAST_DISCHARGE",
    DEL_DATE: "DEL_DATE",
    COS_DATE: "COS_DATE",
    LOADING: "LOADING",
    FIRST_LOADING: "FIRST_LOADING",
    LAST_LOADING: "LAST_LOADING",
    RECEIVE_DATE: "RECEIVE_DATE",
    FIRST_ETB: "1ST_ETB",
    FIRST_ETB_ADD_1: "1ST_ETB+1",
    FIRST_ETB_MINUS_1: "1ST_ETB-1",
    FIRST_ETD: "1ST_ETD",
    FIRST_ETD_ADD_1: "1ST_ETD+1",
    FIRST_ETD_MINUS_1: "1ST_ETD-1",
    SECOND_ETB: "2ND_ETB",
    SECOND_ETB_ADD_1: "2ND_ETB+1",
    SECOND_ETB_MINUS_1: "2ND_ETB-1",
    SECOND_ETD: "2ND_ETD",
    SECOND_ETD_ADD_1: "2ND_ETD+1",
    SECOND_ETD_MINUS_1: "2ND_ETD-1",
    GATE_IN: "GATE_IN",
    GATE_OUT: "GATE_OUT",
    STORAGE_FROM_DATE: "STORAGE_FROM_DATE",
    STORAGE_TO_DATE: "STORAGE_TO_DATE",
    TO_COS_DATE: "TO_COS_DATE",
    GATE_CLOSING_TIME: "GATE_CLOSING_TIME",
    POWER_PLUG_ON: "POWER_PLUG_ON",
    POWER_PLUG_OFF: "POWER_PLUG_OFF",
})