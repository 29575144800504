import _ from "lodash";

export const BillingTypeDroOpts = () => {
    const BILLING_TYPE_1 = "1"
    const BILLING_TYPE_2 = "2"
    const BILLING_TYPE_3 = "3"

    const getInboundDroOptsModel = () => {
        let ret = [BILLING_TYPE_2, BILLING_TYPE_3];

        return [
            ...(_.orderBy(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [], "dropdownLabel"))];
    }

    const getOutBoundDroOptsModel = () => {
        let ret = [BILLING_TYPE_1, BILLING_TYPE_3];

        return [
            ...(_.orderBy(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [], "dropdownLabel"))];
    }

    return {
        getInboundDroOptsModel,
        getOutBoundDroOptsModel
    }
}