import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";

export const FSPUomDroOpts = () => {

    const getFSPUomModel = () => {
        let items = [ChargeConstantCollection.uom.DAY, ChargeConstantCollection.uom.HOUR];

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getFSPUomModel        
    }
}