export const SubChargeTypePolicy = ({
    CODE_BP: "BP",
    CODE_CO: "CO",
    CODE_CS: "CS",
    CODE_CY: "CY",
    CODE_YH: "YH",
    CODE_CY_IS: "CY-IS",
    CODE_YH_IS: "YH-IS",
    CODE_DK: "DK",
    CODE_DO: "DO",
    CODE_DR: "DR",
    CODE_DWT: "DWT", 
    CODE_GB: "GB",
    CODE_GB_IS: "GB-IS",
    CODE_GB_WS: "GB-WS",
    CODE_GNU: "GNU",
    CODE_GNU_IS: "GNU-IS",
    CODE_GRT: "GRT",
    CODE_IS: "IS",
    CODE_ISOH: "ISOH",
    CODE_ISOH_IS: "ISOH-IS",
    CODE_LA: "LA",
    CODE_LA_IS: "LA-IS",
    CODE_LASH: "LASH",
    CODE_LASH_IS: "LASH-IS",
    CODE_MC: "MC",
    CODE_OS: "OS",
    CODE_OS_IS: "OS-IS",
    CODE_PC: "PC",
    CODE_PP: "PP",
    CODE_RESH: "RESH",
    CODE_RESH_IS: "RESH-IS",
    CODE_RP: "RP",
    CODE_VVOL: "VVOL",
    CODE_SC: "SC",
    CODE_SF: "SF",
    CODE_ST: "ST",
    CODE_TC: "TC",
    CODE_EMP: "EMP",
    CODE_CY_OH: "CY-OH",
    CODE_RESH_OH: "RESH-OH",
    CODE_CY_OL: "CY-OL",
    CODE_RESH_OL: "RESH-OL",
    CODE_CY_OW: "CY-OW",
    CODE_RESH_OW: "RESH-OW",
    CODE_CY_WS: "CY-WS",
    CODE_RESH_WS: "RESH-WS",
    CODE_ISOH_WS: "ISOH-WS",
    CODE_LRF: "LRF",
    CODE_CY_MOR: "MOR",
    CODE_CY_UMR: "UMR",
    CODE_MAR_MAR: "MAR",
    CODE_HDL: "HDL",
    CODE_DEMI: "DEMI",
    CODE_DEMX: "DEMX",
    CODE_ITM: "ITM",
    CODE_OBS: "OBS",
    CODE_INTERIM: "INTERIM",
    CODE_VGM: "VGM",
})