import { EventTypePolicy } from "constants/company/EventTypePolicy";

export const EventTypeDroOpts = () => {


    const getEventTypeModel = () => {
        let items = [EventTypePolicy.EVENT_PUBLIC_HOLIDAY, EventTypePolicy.EVENT_TYPHOON];

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getEventTypeModel        
    }
}