import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo } from "react";
import CompanyFspTablePanel from "./CompanyFspTablePanel";
import ChargeSuppleTermsTablePanel from "./ChargeSuppleTermsTablePanel";
import CompanyEmptyPoolTablePanel from "./CompanyEmptyPoolTablePanel";
import CompanyTeuFactorTablePanel from "./CompanyTeuFactorTablePanel";
import CustomerAssignmentTablePanel from "./CustomerAssignmentTablePanel";
import { useCompanyFspVM } from "presentation/hook/Company/useCompanyFspVM";
import { useChargeSuppleTermsVM } from "presentation/hook/Company/useChargeSuppleTermsVM";
import { useCompanyTeuFactorVM } from "presentation/hook/Company/useCompanyTeuFactorVM";
import { useCompanyEmptyPoolVM } from "presentation/hook/Company/useCompanyEmptyPoolVM";
import { useCustomerAssignmentVM } from "presentation/hook/Company/useCustomerAssignmentVM";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";


const CompanyDetailTablePanel:React.FC = () => {
    const [ companyState ] = useCompanyMaintenanceTracked();
    const chargeSuppleTermsVM = useChargeSuppleTermsVM();
    const companyFspVM = useCompanyFspVM();
    const companyTeuFactorVM = useCompanyTeuFactorVM();
    const companyEmptyPoolVM = useCompanyEmptyPoolVM();
    const customerAssignmentVM = useCustomerAssignmentVM();

    const [ companyDtlState ] = useCompanyDetailTracked();
    const {companyId} = companyDtlState;

    const initialTableData = useCallback(async() => {
        if (companyId === 0) return;
        try {
            if(companyId !== 0){
                companyFspVM.initialFspTableData(companyId); 
                chargeSuppleTermsVM.initialChgSuppleTermsTableData(companyId);
                companyEmptyPoolVM.initialEmptyPoolTableData(companyId);
                companyTeuFactorVM.initialTeuFactorTableData(companyId);
                customerAssignmentVM.initialCustAssignTableData(companyId);
            }                         
        } catch(error) {
        }
    }, [chargeSuppleTermsVM, companyEmptyPoolVM, companyFspVM, companyId, companyTeuFactorVM, customerAssignmentVM]);
    
    useEffect(() => {
        if(companyState.isShowDetail)
        initialTableData();
    }, [companyState.isShowDetail, initialTableData])
    
    const memoCompanyFspTable = useMemo(() => {
        return <CompanyFspTablePanel/>
    },[])

    const memoChargeSuppleTermsTable = useMemo(() => {
        return <ChargeSuppleTermsTablePanel/>
    },[])

    const memoCompanyEmptyPoolTable = useMemo(() => {
        return <CompanyEmptyPoolTablePanel/>
    },[])

    const memoCompanyTeuFactorTable = useMemo(() => {
        return <CompanyTeuFactorTablePanel/>
    },[])

    const memoCustomerAssignMentTable = useMemo(() => {
        return <CustomerAssignmentTablePanel/>
    },[])

    return <><div className="main-comp-wrapper">
        <div style={{ width: '100%', height: '100%', maxHeight: '95%', overflow: 'auto' }}>
            <TableWrapper> {memoCompanyFspTable} </TableWrapper>
            <TableWrapper> {memoCompanyEmptyPoolTable} </TableWrapper>
            <TableWrapper> {memoChargeSuppleTermsTable} </TableWrapper>
            <TableWrapper> {memoCustomerAssignMentTable} </TableWrapper>
            <TableWrapper> {memoCompanyTeuFactorTable} </TableWrapper>
        </div>
    </div></>;
}

export default memo(CompanyDetailTablePanel);
