import { CompanyEmptyPoolEntity, EMPTY_COMPANY_EMPTY_POOL_ENTITY } from "domain/entity/Company/CompanyEmptyPoolEntity";
import { CompanyEmptyPoolRepository } from "domain/repository/Company/CompanyEmptyPoolRepo";
import _ from "lodash";
import { commonCompEmptyPoolValidationSchema } from "presentation/constant/Company/CompEmptyPoolValidationSchema";
import { PoolCalculateByDroOpts } from "presentation/constant/DropDownOptions/Company/PoolCalculateByDroOpts";
import { PoolTypeDroOpts } from "presentation/constant/DropDownOptions/Company/PoolTypeDroOpts";
import { PoolUomDroOpts } from "presentation/constant/DropDownOptions/Company/PoolUomDroOpts";
import { Validation } from "presentation/constant/Validation";
import { CompanyDetailModel } from "presentation/model/Company/CompanyDetailModel";

import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CompanyEmptyPoolVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]    
    companyEmptyPoolRepo: CompanyEmptyPoolRepository
}

export const CompanyEmptyPoolVM = ({ dispatch, companyEmptyPoolRepo}: CompanyEmptyPoolVMProps) => {
    const [companyDetailDispatch] = dispatch;

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return companyDetailDispatch(prevState => {
            return {
                ...prevState,
                companyEmptyPoolState: {
                    ...prevState.companyEmptyPoolState,
                    currentCompEmptyPool: {
                        ...prevState.companyEmptyPoolState.currentCompEmptyPool,
                        [fieldKey]: val
                    }
                }          
            }
          });
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        companyDetailDispatch(prevState => ({
            ...prevState,
            companyEmptyPoolState: {
                ...prevState.companyEmptyPoolState,
                currentCompEmptyPool: {
                    ...prevState.companyEmptyPoolState.currentCompEmptyPool,
                    [fieldName]: checked ? "Y" : "N"
                }
            }
        }))
    }

    const loadDropdownOption = async() => {
        companyDetailDispatch(prevState => {            
            return {
                ...prevState,
                dynamicOptions:{
                    ...prevState.dynamicOptions,
                    poolTypeDropdownOptions: PoolTypeDroOpts().getPoolTypeItemList(),
                    poolUomDropdownOptions: PoolUomDroOpts().getPoolUomModel(),
                    poolCalculateByDropdownOptions: PoolCalculateByDroOpts().getPoolCalculateByItemList()
                }
            }
        });
    }

    const onInitDefaultValue = () => {
        companyDetailDispatch(prevState => {
            const compEmptyPool = prevState.companyEmptyPoolState.currentCompEmptyPool;
            return {
                ...prevState,

                companyEmptyPoolState: {
                    ...prevState.companyEmptyPoolState,
                    currentCompEmptyPool:{
                        ...compEmptyPool,
                        poolType: compEmptyPool.id === 0?PoolTypeDroOpts().getPoolTypeItemList()[0].value:compEmptyPool.poolType,
                        uom: compEmptyPool.id === 0?PoolUomDroOpts().getPoolUomModel()[0].value:compEmptyPool.uom,
                        calculateBy: compEmptyPool.id === 0?PoolCalculateByDroOpts().getPoolCalculateByItemList()[0].value:compEmptyPool.calculateBy,
                    }
                }
            }
        });
    }

    const onAdd = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,                
                viewState: {
                    ...prevState.viewState,
                    isAdd: true,
                    isEditable: false,
                    isSaveClicked:false,
                    isShowFsp: false,
                    isShowEmptyPool: true,
                    isShowSuppleTerms: false,
                    isShowCustAssign: false,
                    isShowFactor: false,
                    isShowCustInfo: false,
                    allFormState:{}                    
                },

                companyEmptyPoolState: {
                    ...prevState.companyEmptyPoolState,
                    currentCompEmptyPool: EMPTY_COMPANY_EMPTY_POOL_ENTITY
                }
            }
        });
    }

    const onEdit = (currentCompEmptyPool: CompanyEmptyPoolEntity) => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,                
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isEditable: prevState.viewState.isEditCompDtlInfo,
                    isSaveClicked:false,
                    isShowFsp: false,
                    isShowEmptyPool: true,
                    isShowSuppleTerms: false,
                    isShowCustAssign: false,
                    isShowFactor: false,
                    isShowCustInfo: false,
                    allFormState:{}
                },

                companyEmptyPoolState:{
                    ...prevState.companyEmptyPoolState,
                    currentCompEmptyPool: currentCompEmptyPool
                }
            }
        });
    }

    const handleDelete = () => { 
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,

                viewState: {
                    ...prevState.viewState,
                    isShowDeleteModal: true,
                    dataType: "EMPTY_POOL"
                }        
            }
        });
    }

    const onDelete = async (selectedRows: CompanyEmptyPoolEntity[]) => {
        await companyEmptyPoolRepo.deleteCompEmptyPool(
            selectedRows.map(selectedRow => selectedRow.id) as number[]
        ).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,
                    viewState: {
                        ...prevState.viewState,
                        isShowDeleteModal: false,
                        dataType: ""
                    },
                    
                    companyEmptyPoolState: {
                        ...prevState.companyEmptyPoolState,
                        selectedDatas: [],
                        currentCompEmptyPool: EMPTY_COMPANY_EMPTY_POOL_ENTITY
                    }
                }
            });
        })
    }

    const onSubmit = async (selectedRows: CompanyEmptyPoolEntity[]) => {
        const updatedRows = selectedRows.map((selectedRow) => {            
            return { ...selectedRow, status: 'FINALIZED', confirmedDate: null };
        });

        await companyEmptyPoolRepo.batchUpdateCompEmptyPool(updatedRows).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,
                    
                    companyEmptyPoolState: {
                        ...prevState.companyEmptyPoolState,
                        selectedDatas: [],
                        currentCompEmptyPool: EMPTY_COMPANY_EMPTY_POOL_ENTITY
                    }
                }
            });
        })
    }

    const handleReject = () => { 
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,

                viewState: {
                    ...prevState.viewState,
                    isShowRejectModal: true,
                    dataType: "EMPTY_POOL"
                }        
            }
        });
    }

    const onReject = async (selectedRows: CompanyEmptyPoolEntity[], rejectReason: string) => { 
        const updatedRows = selectedRows.map((selectedRow) => {            
            return { ...selectedRow, status: 'REJECTED', rejectReason: rejectReason };
        });

        await companyEmptyPoolRepo.batchUpdateCompEmptyPool(updatedRows).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,

                    viewState: {
                        ...prevState.viewState,
                        isShowRejectModal: false,
                        dataType: "",
                        rejectReason: ""
                    },
                    
                    companyEmptyPoolState: {
                        ...prevState.companyEmptyPoolState,
                        selectedDatas: [],
                        currentCompEmptyPool: EMPTY_COMPANY_EMPTY_POOL_ENTITY
                    }
                }
            });
        })
    }

    const onApprove = async (selectedRows: CompanyEmptyPoolEntity[]) => { 
        const confirmDate = new Date();
        const updatedRows = selectedRows.map((selectedRow) => {            
            return { ...selectedRow, confirmedDate: confirmDate, rejectReason: null };
        });

        await companyEmptyPoolRepo.batchUpdateCompEmptyPool(updatedRows).then((res) => {
            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,
                    
                    companyEmptyPoolState: {
                        ...prevState.companyEmptyPoolState,
                        selectedDatas: [],
                        currentCompEmptyPool: EMPTY_COMPANY_EMPTY_POOL_ENTITY
                    }
                }
            });
        })
    }

    const onApply = async (selectedRows: CompanyEmptyPoolEntity[]) => { 
        return await companyEmptyPoolRepo.batchUpdateCompEmptyPool(selectedRows)
    }

    const onSelectedCompEmptyPools = (selectedRows: CompanyEmptyPoolEntity[]) => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                companyEmptyPoolState:{
                    ...prevState.companyEmptyPoolState,
                    selectedDatas: selectedRows
                }
            }
        })
    }

    const onSave = async (currentCompEmptyPool: CompanyEmptyPoolEntity, isAdd: boolean ) => {
        const valResult = await Validation(commonCompEmptyPoolValidationSchema).ValidateFormOnly(currentCompEmptyPool);

        if (valResult) {
            let allValResult: {[x: string]: string} = {};  

            if (valResult) {  
                allValResult = { ...allValResult, ...valResult,                    
                    mandatoryCheckFail: 'Please input the missing value.'
                };    
            }


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }                        
                }
            });
            return { "saveCompEmptyPoolFailed": 'Please input the missing value.' };
        } else {
            return await (isAdd ? companyEmptyPoolRepo.addCompEmptyPool(currentCompEmptyPool) : companyEmptyPoolRepo.updateCompEmptyPool(currentCompEmptyPool)).then((data) => {
                if (data && data.toString().startsWith("Error:")) {
                    companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "saveCompEmptyPoolFailed": data.toString() }
                        };
                    });
                    return { "saveCompEmptyPoolFailed": data.toString() };
                } else {
                    return companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            isShowRightPanel: false,
                            viewState: {
                                ...prevState.viewState,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isSaveClicked: false,
                                lastEditRowId: '',
                                isSliderOpen: false,
                                allFormState: {},

                                isShowFsp: false,
                                isShowEmptyPool: false,
                                isShowSuppleTerms: false,
                                isShowCustAssign: false,
                                isShowFactor: false,
                                isShowCustInfo: false
                            },

                            companyEmptyPoolState: {
                                ...prevState.companyEmptyPoolState,
                                selectedDatas: [],
                                currentCompEmptyPool: EMPTY_COMPANY_EMPTY_POOL_ENTITY
                            }
                        }
                    })
                   
                }
            })
        }
    }

    const initialEmptyPoolTableData = async (compnayId: number) => {
        await companyEmptyPoolRepo.searchCompEmptyPool(compnayId).then(data => {
            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    companyEmptyPools: data            
                }
            })
        })
    }

    return {        
        onAdd: onAdd,
        onEdit: onEdit,
        onDelete: onDelete,
        onSubmit: onSubmit,
        onReject: onReject,
        onApprove: onApprove,
        onSelectedCompEmptyPools: onSelectedCompEmptyPools, 
        onFieldChange: onFieldChange,
        onCheckboxChange: onCheckboxChange,
        onSave: onSave,
        onApply: onApply,
        initialEmptyPoolTableData: initialEmptyPoolTableData,
        handleReject: handleReject,
        loadDropdownOption:loadDropdownOption,
        onInitDefaultValue: onInitDefaultValue,
        handleDelete: handleDelete
    }
}