import { ShiftRepoImpl } from "domain/repository/Common/ShiftRepoImpl";
import { CompanyChargeSuppleTermsRepoImpl } from "domain/repository/Company/CompanyChargeSuppleTermsRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { ChargeSuppleTermsVM } from "presentation/viewModel/Company/ChargeSuppleTermsVM";
import { useMemo } from "react";

export const useChargeSuppleTermsVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const chargeSuppleTermsVM = useMemo(() =>
        ChargeSuppleTermsVM({
            dispatch: [setCompanyDetailState],
            companyChargeSuppleTermsRepo: CompanyChargeSuppleTermsRepoImpl(),            
            masterDataRepo: MasterDataRepoImpl(),
            shiftRepo: ShiftRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl()
        }), [setCompanyDetailState])

    return chargeSuppleTermsVM
}