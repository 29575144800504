import { TerminalEntity } from "../Terminal/TerminalEntity"
import { CompanyEntity } from "./CompanyEntity"

export interface CompanyTerminalEntity {
    id: number | null,
    versionIdentifier: {
        version?: string
    },
    companyId: number | null,
    operatingTmlId: number | null,
    company: CompanyEntity | null,
    terminal: TerminalEntity | null
    
    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_TERMINAL_ENTITY : CompanyTerminalEntity = {
    id: null,
    versionIdentifier: {},
    companyId: null,
    operatingTmlId: null,
    company: null,
    terminal: null
}