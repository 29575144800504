export const FSPCountingValuePolicy = ({
    ETB: "ETB",
    ETB_ADD_1: "ETB+1",
    ETB_MINUS_1: "ETB-1",
    ETD: "ETD",
    ETD_ADD_1: "ETD+1",
    ETD_MINUS_1: "ETD-1",
    DISCHARGING: "Discharging",
    FIRST_DISCHARGING: "First Discharging",
    LAST_DISCHARGING: "Last Discharging",
    DELIVERY_DATE: "Delivery Date",
    COS: "COS",
    TO_COS: "COS",
    LOADING: "Loading",
    FIRST_LOADING: "First Loading",
    LAST_LOADING: "Last Loading",
    RECEIVE_DATE: "Receive Date",
    FIRST_LEG_ETB: "1st Leg ETB",
    FIRST_LEG_ETB_ADD_1: "1st Leg ETB+1",
    FIRST_LEG_ETB_MINUS_1: "1st Leg ETB-1",
    FIRST_LEG_ETD: "1st Leg ETD",
    FIRST_LEG_ETD_ADD_1: "1st Leg ETD+1",
    FIRST_LEG_ETD_MINUS_1: "1st Leg ETD-1",
    FIRST_LEG_DISCHARGING: "1st Leg Discharging",
    FIRST_LEG_FIRST_DISCHARGING: "1st Leg First Discharging",
    FIRST_LEG_LAST_DISCHARGING: "1st Leg Last Discharging",
    SECOND_LEG_ETB: "2nd Leg ETB",
    SECOND_LEG_ETB_ADD_1: "2nd Leg ETB+1",
    SECOND_LEG_ETB_MINUS_1: "2nd Leg ETB-1",
    SECOND_LEG_ETD: "2nd Leg ETD",
    SECOND_LEG_ETD_ADD_1: "2nd Leg ETD+1",
    SECOND_LEG_ETD_MINUS_1: "2nd Leg ETD-1",
    SECOND_LEG_LOADING: "2nd Leg Loading",
    SECOND_LEG_FIRST_LOADING: "2nd Leg First Loading",
    SECOND_LEG_LAST_LOADING: "2nd Leg Last Loading",
    GATE_IN: "Gate In",
    GATE_OUT: "Gate Out",
    STORAGE_FROM_DATE: "Storage From Date",
    STORAGE_TO_DATE: "Storage To Date",
    GATE_CLOSING_TIME: "Receival Cutoff Time",
    POWER_PLUG_ON: "Power Plug On",
    POWER_PLUG_OFF: "Power Plug Off",
})