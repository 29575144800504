export interface CompanyEmptyPoolEntity {
    id: number,
    versionIdentifier: {
        version?: string
    },
    companyId: number | null,
    operatingTml: string | null,
    effectiveDate: Date | null,
    poolSize: number | null,
    poolType: string | null,
    status: string | null,
    confirmedDate: Date | null,
    remarks: string | null,
    rejectReason: string | null,
    priority: number | null,
    sharedPoolByMembers: string | null,
    uom: string | null,
    calculateBy: string | null,
    activeInd: string | null,
    isMaster: true | false,
    isSharedPool: true | false,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_EMPTY_POOL_ENTITY : CompanyEmptyPoolEntity = {
    id: 0,
    versionIdentifier: {},
    companyId: null,
    operatingTml: null,
    effectiveDate: null,
    poolSize: null,
    poolType: null,
    status: "NEW",
    confirmedDate: null,
    remarks: null,
    rejectReason: null,
    priority: null,
    sharedPoolByMembers: null,
    uom: null,
    calculateBy: null,
    activeInd: "Y",
    isMaster: false,
    isSharedPool: false
}