import _ from "lodash";

export const SuppleTermsRefDroOpts = () => {
    const LATE_ARRIVAL_GRACE  = {
        N_A: "N/A",
        MULTIPLY_BY_QC: "Multiply by QC"        
    };

    const HANDLE_SHORTSEA_AS = ["SD", "SB"];

    const getLateArrGraceDroOptsModel = () => {
        let ret = LATE_ARRIVAL_GRACE;

        return [
            ...(_.map(
                _.toPairs(ret), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: key, value: value }
                )
            ) ?? [])];
    }

    const getHandleShortSeaAsDroOptsModel = () => {
        let ret = HANDLE_SHORTSEA_AS;

        return [
            ...(ret?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getLateArrGraceDroOptsModel,
        getHandleShortSeaAsDroOptsModel
    }
}