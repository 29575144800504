export interface CompanySearchCriteria {    
    companyCode?: string | null,
    companyName?: string | null,
    activeInd?: string | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_COMPANY_SEARCH_CRITERIA : CompanySearchCriteria = {
    companyCode: null,
    companyName: null,
    activeInd: null

}