export const SupplementaryTermsPolicy = ({

    IN_YARD_DAY_LIMIT: "IN_YARD_DAY_LIMIT",
    LATE_ARRIVAL_GRACE: "LATE_ARRIVAL_GRACE",
    INTENSIVE_SUR_PERC: "INTENSIVE_SUR_PERC",
    DK_BERTH_GRACE_PERIOD: "DK_BERTH_GRACE",
    HANDLE_SHORTSEA_AS: "HANDLE_SHORTSEA_AS",
    RAIL_IMO_SUR: "RAIL_IMO_SUR",
    RAIL_RFR_SUR: "RAIL_RFR_SUR",
    RAIL_OW_SUR: "RAIL_OW_SUR",
    BILLING_TYPE_INBOUND_NON_CY: "BILLING_TYPE_INBOUND_NON_CY",
    BILLING_TYPE_OUTBOUND_NON_CY: "BILLING_TYPE_OUTBOUND_NON_CY",
    BILLING_CYCLE: "BILLING_CYCLE"
})