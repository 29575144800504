import { PoolUomPolicy } from "constants/company/PoolUomPolicy";

export const PoolUomDroOpts = () => {

    const getPoolUomModel = () => {
        let items = [];

        items.push(PoolUomPolicy.TEU);
        items.push(PoolUomPolicy.ITEM);

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
            })) ?? [])];
    }

    return {
        getPoolUomModel        
    }
}