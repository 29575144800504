export const ShiftType = {
    SHIFT_OPERATION:"OPERATION",
    SHIFT_INTENSIVE:"INTENSIVE",
    SHIFT_MOORING:"MOORING",    
    SHIFT_UNMOORING:"UNMOORING",
}
export const ShiftDay = {
    SHIFT_DAY_MONDAY : "MONDAY",
    SHIFT_DAY_TUESDAY : "TUESDAY",
    SHIFT_DAY_WEDNESDAY : "WEDNESDAY",
    SHIFT_DAY_THURSDAY : "THURSDAY",
    SHIFT_DAY_FRIDAY : "FRIDAY",
    SHIFT_DAY_SATURDAY : "SATURDAY",
    SHIFT_DAY_SUNDAY : "SUNDAY",
    SHIFT_DAY_PUBLIC_HOLIDAY : "PUBLIC_HOLIDAY",
}
export const eventType = {
    PUBLIC_HOLIDAY : "PUBLIC_HOLIDAY",
    TYPHOON : "TYPHOON",
}