export interface CompanyChargeSuppleTermsEntity {
    id: number,
    versionIdentifier: {
        version?: string
    },
    operatingTml: string | null,
    companyId: number | null,
    supplementaryTerms: string | null,
    supplementaryTermsRef: string | null,
    value: number | null,
    effectiveDate: Date | null,
    status: string | null,
    remarks: string | null,
    rejectReason: string | null,
    confirmedDate: Date | null,
    cntrMovement: string | null,
    cntrStatus: string | null,
    activeInd: string | null,
    chargeType: string | null,
    subChargeType: string | null,
    modality: string | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY : CompanyChargeSuppleTermsEntity = {
    id: 0,
    versionIdentifier: {},
    operatingTml: null,
    companyId: null,
    supplementaryTerms: null,
    supplementaryTermsRef: null,
    value: null,
    effectiveDate: null,
    status: "NEW",
    remarks: null,
    rejectReason: null,
    confirmedDate: null,
    cntrMovement: null,
    cntrStatus: null,
    activeInd: "Y",
    chargeType: null,
    subChargeType: null,
    modality: null
}