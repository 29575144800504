export interface CustomerEntity {
    id: number,
	customerCode: string,
	nif: string | null,
	customerDesc1: string | null,
	filler: string | null,
	customerDesc2: string | null,
	paymentTerm: number | null,
	paymentUom: string | null,
	paymentCutoffDay1: number | null,
	paymentCutoffDay2: number | null,
	paymentCutoffDay3: number | null,
	interestRate: number | null,
	billingAddress1: string | null,
	billingAddress2: string | null,
	billingAddress3: string | null,
	billingAddress4: string | null,
	statementAddress1: string | null,
	statementAddress2: string | null,
	statementAddress3: string | null,
	principleAddress1: string | null,
	principleAddress2: string | null,
	principleAddress3: string | null,
	telephoneNo: string | null,
	faxNo: string | null,
	glAccount: string | null,
	countryKey: string | null,
	contactPerson: string | null,
	settlementCurrencyCode: string | null,
	city: string | null,
	county: string | null,
	state: string | null,
	province: string | null,
	postalCode: string | null,
	country: string | null,
	creditLimit: number | null,
	outstandingBal: number | null,
	region: string | null,
	shippingLineAddress1: string | null,
	shippingLineAddress2: string | null,
	shippingLineAddress3: string | null,
	customerType: string | null,
	type: string | null,
	ntnNo: string | null,
	gstNo: string | null,
	customerAddress: string | null,
	emailAddress: string | null,
	activeInd: 'Y' | 'N',
    unFinalizedAmount: number | null,
    netBal: number | null,
    creditLimitExceeded: number | null,
    customerName: string | null,


    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_CUSTOMER_ENTITY : CustomerEntity = {
    id: 0,
    customerCode: '',
    nif: null,
    customerDesc1: null,
    filler: null,
    customerDesc2: null,
    paymentTerm: null,
    paymentUom: null,
    paymentCutoffDay1: null,
    paymentCutoffDay2: null,
    paymentCutoffDay3: null,
    interestRate: null,
    billingAddress1: null,
    billingAddress2: null,
    billingAddress3: null,
    billingAddress4: null,
    statementAddress1: null,
    statementAddress2: null,
    statementAddress3: null,
    principleAddress1: null,
    principleAddress2: null,
    principleAddress3: null,
    telephoneNo: null,
    faxNo: null,
    glAccount: null,
    countryKey: null,
    contactPerson: null,
    settlementCurrencyCode: null,
    city: null,
    county: null,
    state: null,
    province: null,
    postalCode: null,
    country: null,
    creditLimit: null,
    outstandingBal: null,
    region: null,
    shippingLineAddress1: null,
    shippingLineAddress2: null,
    shippingLineAddress3: null,
    customerType: null,
    type: null,
    ntnNo: null,
    gstNo: null,
    customerAddress: null,
    emailAddress: null,
    activeInd: 'Y',
    unFinalizedAmount: null,
    netBal: null,
    creditLimitExceeded: null,
    customerName: null,
}